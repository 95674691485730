/*
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-04-29 16:15:30
 * @LastEditors: llg
 * @LastEditTime: 2022-06-06 17:46:12
 */
import { doPost } from './index'
import axios from '@/utils/request';

export function uploadPrinSet() {
    return axios.post({
        url: '/api/v1/uploaFile/uploadPrintSetImg',
    }).then((res) => {
        return res
    })
}

// 上传文件
export const uploadFile = data => doPost('/api/v1/uploadFile/uploadImg')(data)
