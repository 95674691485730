<template>
    <div style="height:100%">
        <div class="user-info">
            <div class="title">用户资料</div>
            <div class="info">
                <div>
                    <span>用户名称：</span>
                    <span>{{currentUser.sender}}</span>
                </div>
                <!-- <div>
                    <span>来源：</span>
                    <span>微信</span>
                </div>
                <div>
                    <span>终端：</span>
                    <span>微信</span>
                </div> -->
            </div>
        </div>
        <div class="bottom">
            <div class="common-replies">
                <span>常用回复</span>
                <el-popover placement="left" title="快捷回复" width="250" trigger="click" v-model="visible">
                    <div>
                        <el-input v-model="value" placeholder="快捷回复"></el-input>
                    </div>
                    <div style="text-align: right; margin-top: 12px">
                        <el-button size="mini" type="default" @click="visible = false">取消</el-button>
                        <el-button type="primary" size="mini" @click="addCommonreply" :disabled="!value">确定</el-button>
                    </div>
                    <span slot="reference">添加</span>
                </el-popover>
            </div>
            <div class="common-message" v-loading="loading">
                <div v-for="item in data" :key="item.id">
                    <span @click="sendMessage(item)">{{item.content}}</span>
                    <span>
                        <el-popconfirm title="确定删除该条常用回复吗？" @confirm="deleteCommonreply">
                            <i class="el-icon-close" slot="reference" @click="currentCommonreply = item"></i>
                        </el-popconfirm>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { commonreply } from '@/api/chatApi';
export default {
    props: {
        currentUser: {
            type: Object,
            default: {}
        }
    },
    components: {
    },
    data() {
        return {
            visible: false,
            value: '',
            data: [],
            currentCommonreply: {},
            loading: false
        };
    },

    methods: {
        async getCommonreply() {
            this.loading = true;
            const res = await commonreply('get');
            this.data = res.data;
             this.loading = false;
        },
        async addCommonreply() {
            const res = await commonreply('post', {
                content: this.value
            });
            if (res.code == 0) {
                this.getCommonreply();
                this.visible = false;
                this.value = null;
            }
        },
        // 删除常用回复
        async deleteCommonreply() {
            const res = await commonreply('delete', { id: this.currentCommonreply.id });
            if (res.code == 0) {
                this.getCommonreply();
            }
        },
        sendMessage(item) {
            this.$emit('sendCommonreply', item)
        }
    },
    mounted() {
        this.getCommonreply()
    }
};
</script>
<style lang="scss" scoped>
.bottom {
    height: calc(100% - 68px);
}
.user-info {
    padding: 8px 12px;
    border-bottom: 1px solid #e8e8e8;
    width: calc(100% - 24px);
    .title {
        font-weight: bold;
        margin-bottom: 12px;
    }
    .info {
        > div {
            color: #666666;
            margin-bottom: 12px;
        }
        > div:last-child {
            margin-bottom: 6px;
        }
    }
}
.common-replies {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    padding: 0 12px;
    user-select: none;
    > span:nth-child(1) {
        font-weight: bold;
        color: #4a4a4a;
    }
    > span:nth-child(2) {
        font-weight: bold;
        color: #00d2ff;
        cursor: pointer;
    }
}
.common-message {
    height: calc(100% - 33px);
    overflow: auto;
    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 36px;
        border-bottom: 1px solid #e8e8e8;
        cursor: pointer;
        padding: 0 12px;
        user-select: none;
        > span:nth-child(1) {
            width: calc(100% - 16px);
            height: 100%;
            line-height: 36px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
</style>
