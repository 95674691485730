import store from "@/store";

export default function componentAuth(routeName) {
    let menu = store.getters['menu/menu'];
    let hasAuth = deepMenu(menu.children, routeName);
    return hasAuth;
}

function deepMenu(menu, routeName) {
    for (let i = 0; i < menu.length; i++) {
        let item = menu[i];
        if (item.name === routeName) {
            return true;
        }
        if (item.children && item.children.length) {
            if (deepMenu(item.children, routeName)) {
                return true;
            }
        }
    };
    return false;
}