<!--
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-09-09 10:45:34
 * @LastEditors: llg
 * @LastEditTime: 2022-10-20 11:10:17
-->
<template>
    <div>
        <notifications v-show="!minimize" ref="myNotification" :group="'myNotification'" :duration="-1"
            :closeOnClick="false" position="bottom right" :width="400" :speed="500" animation-type="velocity">
            <template slot="body">
                <div class="my-notification">
                    <div class="header">
                        <div class="left">
                            <span>新订单提醒</span>
                            <span class="tip">（{{ data.length }}笔）</span>
                        </div>
                        <div class="right">
                            <i class="el-icon-close" @click="minimize = true"></i>
                        </div>
                    </div>
                    <div class="content">
                        <div class="title">
                            <span>订单号</span>
                            <span>来源</span>
                            <span :style="{ 'padding-right': data.length > 6 ? '6px' : '0' }">操作</span>
                        </div>
                        <div class="message">
                            <div v-for="(item, index) in data" :key="index">
                                <span>{{ item.id }}</span>
                                <span>{{ item.sourceName }}</span>
                                <span class="opera" @click="jump(item)">查看</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </notifications>
        <!-- <iframe allow="autoplay" :src="require('@/assets/audio/message.mp3')" v-if="play" style="display:none"></iframe> -->
        <audio :src="audioSrc" id="audio" @ended="overAudio"></audio>
        <div v-show="minimize" class="minimize" @click="messageClick" @mousedown="mousedown" :style="{ top: messageTop }">
            {{ data.length }} 笔</div>
    </div>
</template>

<script>
import { getOrderDetailList } from "@/api/phpApi/orderApi";
export default {
    name: 'MyNotification',
    props: {
        data: {
            type: Array,
            default: [],
            audio: null,
        }
    },
    data() {
        return {
            visible: false,
            audioSrc: require('@/assets/audio/message.mp3'),
            play: false,
            minimize: false,
            messageTop: '80vh',
            canClick: true,
            timer: null
        };
    },
    mounted() {

    },
    methods: {
        show(group = 'myNotification') {
            if (!this.visible) {
                this.$notify({ group });
                this.visible = true;
                this.canClick = true;
            };
            this.messagePlay();
        },
        closeNotification() {
            this.visible = false;
            if (this.$refs.myNotification.active && this.$refs.myNotification.active.length) {
                this.$notify.close(this.$refs.myNotification.active[0].id)
            }
        },
        messagePlay() {
            if (this.audio != null) {
                this.audio.pause();
                this.audio = null;
            }
            this.audio = document.getElementById('audio');
            const promise = this.audio.play();
            if (promise !== undefined) {
                promise.then(res => {

                }).catch(error => {
                    this.$confirm('是否授权本次访问自动播放音频?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        if (this.audio) this.audio.play();
                    }).catch(() => {

                    });
                })
            }
        },
        overAudio() {
            if (this.audio && !this.timer && this.data.length) {
                this.timer = setTimeout(() => {
                    this.audio.play();
                    clearTimeout(this.timer);
                    this.timer = null;
                }, 60000)
            }
        },
        stopAudio() {
            if (this.audio) {
                this.audio.pause();
                this.audio = null;
            }
        },
        async jump(order) {
            let sourceRoute;
            if (order.source == 217) {
                sourceRoute = '/business/iflytek/orders';
            } else if (order.source == 219) {
                sourceRoute = '/business/wps/orders';
            } else if (order.source == 221) {
                sourceRoute = '/business/mini-program/order';
            }
            if (this.$route.path !== sourceRoute) {
                this.$router.push(sourceRoute)
            };
            let sourceOrder = {};
            sourceOrder[sourceRoute] = order.id;
            setTimeout(() => {
                this.$store.dispatch("order/setSourceOrder", sourceOrder);
            }, 300)
            setTimeout(() => {
                this.$store.dispatch("order/setSourceOrder", {});
            }, 1000)
        },
        mousedown(e) {
            setTimeout(() => {
                this.canClick = true;
                //去除默认样式 - 避免拖动元素出现禁止图标
                e.preventDefault && e.preventDefault();
                //获取目标元素
                let odiv = e.target;

                //算出鼠标相对元素的位置
                let disY = e.clientY - odiv.offsetTop;
                let clientHeight = document.documentElement.clientHeight || document.body.clientHeight
                //监听鼠标移动事件
                document.onmousemove = (e) => {
                    //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                    let top = e.clientY - disY;
                    //重新赋值

                    if (top >= clientHeight - 30) {
                        top = clientHeight - 30;
                    } else if (top <= 110) {
                        top = 110
                    }
                    this.messageTop = top + 'px';
                    this.canClick = false;
                };

                //监听鼠标松开
                document.onmouseup = (e) => {
                    document.onmousemove = null;
                    document.onmouseup = null;
                    setTimeout(() => {
                        this.canClick = true;
                    })

                };
            }, 20)
        },
        messageClick() {
            if (!this.canClick) return;
            this.minimize = false;
        }
    },
};
</script>

<style lang="scss">
.minimize {
    //position: fixed;
    //right: 0px;
    //top: 80vh;
    border: 1px solid #ddd;
    padding: 6px 12px;
    border-radius: 16px 0px 0px 16px;
    background: rgb(25, 26, 35);
    color: #fff;
    cursor: pointer;
    z-index: 99999;
    user-select: none;
}

.vue-notification-group {
    z-index: 4000 !important;
    position: relative !important;
}

.vue-notification-wrapper {
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 30%);
    font-size: 15px;
    border-radius: 8px 8px 0 0;

    .my-notification {
        background: #fff !important;
        color: #000 !important;
        border-left: 0 !important;

        .header {
            padding: 6px 12px;
            border-bottom: 1px solid #ddd;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;

            .tip {
                color: red;
                font-size: 13px;
            }

            i {
                font-size: 18px;
                cursor: pointer;
            }
        }

        .content {
            padding: 6px 0 0px 14px;
            font-size: 13px;

            .title {
                font-size: 15px;
                font-weight: bold;
                display: flex;
                justify-content: space-between;
                padding-right: 14px;

                >span {
                    width: 112px;
                    text-align: center;
                    flex-shrink: 0;
                }

                >span:first-of-type {
                    width: 186px;
                    text-align: left;
                }

                >span:last-of-type {
                    width: 40px;
                    text-align: center;
                }
            }

            .message {
                overflow: auto !important;
                height: 174px;

                >div {
                    padding: 6px 0;
                    display: flex;
                    justify-content: space-between;
                    padding-right: 14px;

                    >span {
                        width: 112px;
                        text-align: center;
                        color: #333;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        flex-shrink: 0;
                    }

                    >span:first-of-type {
                        width: 186px;
                        text-align: left;
                    }

                    >span:last-of-type {
                        width: 40px;
                        text-align: center;
                    }

                    .opera {
                        color: #3296fa;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>
