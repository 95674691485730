/*
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-09-13 16:16:35
 * @LastEditors: llg
 * @LastEditTime: 2022-09-15 16:01:37
 */

const state = {
    sourceOrder: {},
    // 配送的物流公司
    expressInfo: {}
}

const getters = {
    sourceOrder: (state) => state.sourceOrder,
    getExpressInfo: (state) => state.expressInfo
}

const mutations = {
    initState() {
        let orderSetting = JSON.parse(localStorage.getItem("sys-order-setting"));
        if (orderSetting && orderSetting.expressInfo) {
            state.expressInfo = orderSetting.expressInfo
        }
    },
    setSourceOrder(state, sourceOrder) {
        state.sourceOrder = sourceOrder
    },
    setExpressInfo(state, expressInfo) {
        state.expressInfo = expressInfo;
        saveStoreStorage(state)
    }
}

const actions = {
    initState({ commit }) {
        commit('initState')
    },
    setSourceOrder({ commit }, e) {
        commit('setSourceOrder', e)
    },
    setExpressInfo({ commit }, e) {
        commit('setExpressInfo', e)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}



function saveStoreStorage(info) {
    localStorage.setItem("sys-order-setting", JSON.stringify(info))
}
