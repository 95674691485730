const state = {
    columnsCache: {},
}

const getters = {
    columnsCache: (state) => state.columnsCache,
}

const mutations = {
    initState() {
        let columnsCache = JSON.parse(localStorage.getItem("sys_columns_cache"))
        if (columnsCache) {
            state.columnsCache = columnsCache;
        }
    },
    setColumnsCache(state, columnsCache) {
        let columns = JSON.parse(localStorage.getItem("sys_columns_cache")) || {};
        if (!columns[columnsCache.routeName]) {
            columns[columnsCache.routeName] = columnsCache.columns
        } else {
            columns[columnsCache.routeName] = columnsCache.columns;
        }
        state.columnsCache = columns;
        saveStoreStorage(columns);
    },
    resetColumnsCache(state, routeName) {
        let columns = JSON.parse(localStorage.getItem("sys_columns_cache")) || {};
        if (columns[routeName]) {
            delete columns[routeName];
            state.columnsCache = columns;
            saveStoreStorage(columns);
        }
    },
}

const actions = {
    initState({ commit }, e) {
        commit('initState', e)
    },
    setColumnsCache({ commit }, e) {
        commit('setColumnsCache', e)
    },
    resetColumnsCache({ commit }, e) {
        commit('resetColumnsCache', e)
    }
}

function saveStoreStorage(columns) {
    localStorage.setItem("sys_columns_cache", JSON.stringify(columns))
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}