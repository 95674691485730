import { post } from '@/api'
import { doUpload } from '@/api'

export default {
    // 获取公告列表
    getNotificationList: data => post('/api/v1/notification/getNotificationList')(data),
    // 创建
    createNotification: data => post('/api/v1/notification/createNotification')(data),
    // 更新
    updateNotification: data => post('/api/v1/notification/updateNotification')(data),
    // 删除
    deleteNotification: data => post('/api/v1/notification/deleteNotification')(data),
    // 发布
    publishNotification: data => post('/api/v1/notification/publishNotification')(data),
    // 获取公告详情
    getNotificationInfo: data => post('/api/v1/notification/getNotificationInfo')(data),
    // 导出公告
    exportNotificationList: data => post('/api/v1/notification/exportNotificationList')(data),
    // 导入
    importBusinessSettledList: data => doUpload('/api/v1/notification/importBusinessSettledList')(data),
    // 获取营业中商家
    getBusinessSettledList: data => post('/api/v1/notification/getBusinessSettledList')(data),
}

export const noticeBusinessApi = {
    getNotificationList: data => post('/api/v1/userNotification/getNotificationList')(data),
    getNotificationNotReadCount: data => post('/api/v1/userNotification/getNotificationNotReadCount')(data),
    getNotificationInfo: data => post('/api/v1/userNotification/getNotificationInfo')(data),
    deleteNotification: data => post('/api/v1/userNotification/deleteNotification')(data),
    userReads: data => post('/api/v1/userNotification/userReads')(data),
    orderShipmentsReads: data => post('/api/v1/orderNotification/orderShipmentsReads')(data),
}
