const setting = {
    systemName: '66印管理平台', // 系统名称
    defaultTheme: 'dark', // 默认主题
    themeModel: 'white', // 主题模式
    themeList: [ // 菜单主题（选中颜色、hover颜色前往MenuLeft组件修改） 
        {
            theme: 'dark', // 主题名称
            menuLeftBc: '#191A23', // 背景色
            textColor: '#BABBBD', // 文字颜色
            activeColor: '#ffffff', // 文字选中颜色
            iconColor: '#BABBBD', // 图标颜色
            iconColorActive: '#FFFFFF', // 图标选中颜色
            tabbarBackground: '#FFFFFF' // 顶栏背景色
        }, {
            theme: 'white',
            menuLeftBc: '#ffffff',
            textColor: '#515a6e',
            activeColor: '#3296fa',
            iconColor: '#515a6e',
            iconColorActive: '#333333',
            tabbarBackground: '#FFFFFF'
        }
    ]
}

export default setting;