const state = {
    onlineUser: {},
    userInfo: {},
    customerInfo: {},
    userSource: ''
}

const getters = {
    onlineUser: (state) => state.onlineUser,
}

const mutations = {
    setOnlineUser(state, list) {
        state.onlineUser = list
    },
    setUserInfo(state, data) {
        state.userInfo = data;
    },
    setCustomerInfo(state, data) {
        state.customerInfo = data;
    },
    setUserSource(state, data) {
        state.userSource = data;
    }
}

const actions = {
    setOnlineUser({ commit }, e) {
        commit('setOnlineUser', e)
    },
    setUserInfo({ commit }, e) {
        commit('setUserInfo', e)
    },
    setCustomerInfo({ commit }, e) {
        commit('setCustomerInfo', e)
    },
    setUserSource({ commit }, e) {
        commit('setUserSource', e)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}