<template>
    <div class="chat-user">
        <div class="title">
            <span>当前会话</span>
            <el-popover placement="top" width="160" v-model="visible">
                <p>确定清除所有未读信息吗？</p>
                <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="default" @click="visible = false">取消</el-button>
                    <el-button type="danger" size="mini" @click="visible = false;clearUnRead()">清除</el-button>
                </div>
                <i class="el-icon-delete" slot="reference"></i>
            </el-popover>
        </div>
        <div style="height: calc(100% - 39px);overflow: auto;">
            <div class="search">
                <el-input v-model="searchValue" @input="search" placeholder="搜索聊天记录" prefix-icon="el-icon-search" clearable :disabled="!currentGroup.children"></el-input>
            </div>
            <div class="chat-list" v-for="(item, index) in (searchValue != '' ? userMessage: currentGroup.children)" :key="index" :class="{'active': index == currentTab}" @click="selectUser(item, index)">
                <div>
                    <div class="logo">
                        <el-badge :value="item.unReadCount" :hidden="item.unReadCount == 0">
                            <el-avatar icon="el-icon-user-solid"></el-avatar>
                        </el-badge>
                    </div>
                    <div class="list">
                        <div>
                            <span>{{item.senderName}}</span>
                            <span class="time">{{getLastUserMessage(item).time}}</span>
                        </div>
                        <div style="position: relative;">
                            <span>{{getLastUserMessage(item).content}}</span>
                            <span style="position: absolute;right: 0;">
                                <span style="color:red;font-weight:bold">{{item.countdown <= 0 ? '已超时' : item.countdown }}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { sendMessage } from '@/utils/websocket';
export default {
    props: {
        currentGroup: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    components: {
    },
    data() {
        return {
            currentTab: null,
            currentUser: {},
            visible: false,
            searchValue: '',
            userMessage: []
        };
    },

    methods: {
        selectUser(user, index) {
            this.currentTab = index;
            this.currentUser = user;
            this.currentUser.unReadCount = 0;
            sendMessage({
                ChatMessageType: 423,
            })
            this.$store.commit('chat/setUserInfo', this.currentUser)
            this.$emit('getCurrentUser', this.currentUser)
        },
        getLastUserMessage(msg) {
            const { customerInfo } = this.$store.state.chat;
            const list = msg.children.filter(v => v.SenderId != customerInfo.SenderId);
            if (list.length) {
                const last = list[list.length - 1];
                return {
                    time: last.ChatLogSendTime,
                    content: last.ChatLogContent.screen_type == 2 ? '[图片]' : last.ChatLogContent.text_content,
                    countdown: last.countdown
                }
            }
            return {
                time: '',
                content: ''
            }
        },
        search() {
            if (this.searchValue == '') return;
            this.userMessage = [];
            this.currentGroup.children.forEach(item => {
                if (item.children && item.children.length) {
                    for (let i = 0; i < item.children.length; i++) {
                        if (item.children[i].ChatLogContent.text_content.indexOf(this.searchValue) > -1) {
                            this.userMessage.push(item)
                            break;
                        }
                    }
                }
            });
        },
        clearUnRead() {
            this.$emit('clearUnRead')
        }
    },
    watch: {
        currentGroup: {
            handler(val) {
                this.search()
            },
            deep: true,
        }
    }
};
</script>
<style lang="scss" scoped>
.chat-user {
    width: 22%;
    display: inline-block;
    border-right: 1px solid #e8e8e8;
    background: #fff;
    box-shadow: 0px 0px 7px 3px rgba(62, 62, 62, 0.05);
    user-select: none;
    :deep(.el-badge__content) {
        height: 16px;
        line-height: 16px;
        background: red;
        padding: 0 5px;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .search {
        padding: 6px;
        border-bottom: 1px solid #ddd;
    }

    .title {
        border-bottom: 1px solid #e8e8e8;
        height: 38px;
        display: flex;
        justify-content: space-between;
        padding: 0 12px;
        align-items: center;
        i {
            font-size: 16px;
            cursor: pointer;
        }
    }
    .chat-list {
        display: flex;
        align-items: center;
        &.active {
            background: #a8f0ff;
        }
        > div {
            display: flex;
            align-items: center;
            width: calc(100% - 12px);
            height: 40px;
            border-bottom: 1px solid #e8e8e8;
            padding: 6px;
            .logo {
                width: 36px;
                height: 36px;
                padding: 0 12px 0 6px;
                :deep(.el-avatar) {
                    width: 36px;
                    height: 36px;
                    line-height: 36px;
                }
            }
            .list {
                cursor: pointer;
                width: calc(100% - 60px);
                padding-right: 4px;
                > div {
                    display: flex;
                    justify-content: space-between;
                    color: #696969;
                    height: 16px;
                    line-height: 16px;
                }
                > div:nth-child(1) {
                    margin-bottom: 4px;
                }
                > div:nth-child(2) {
                    span:nth-child(1) {
                        max-width: 240px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                }
                .time {
                    color: #c8c8c8;
                }
            }
        }
    }
}
</style>
