<template>
    <div class="topbar" :style="{width: topBarWidth}">
        <div class="menu">
            <div class="left" style="display: flex">
                <svg class="svg-icon" aria-hidden="true" @click="goPage('/')">
                    <use xlink:href="#iconzhaopian-copy"></use>
                </svg>
                <i class="el-icon-s-operation" @click="visibleMenu" v-if="showMenuButton"></i>
                <i class="el-icon-refresh" @click="reload()" v-if="showRefreshButton"></i>
                <breadcrumb v-if="showCrumbs" />
            </div>

            <div class="right">
                <div @click="dialogVisible = true" v-if="appList.length > 1" class="change-app">
                    <span>切换系统</span>
                    <i class="el-icon-arrow-down"></i>
                </div>
                <div class="screen" @click="fullScreen" v-if="!isFullScreen">
                    <i class="el-icon-full-screen"></i>
                </div>
                <div class="screen" @click="exitScreen" v-else>
                    <i class="el-icon-rank"></i>
                </div>
                <div class="user">
                    <el-dropdown @command="goPage">
                        <div>
                            <img class="cover" :src="headerImg" style="float: left" />
                            <span class="name">{{userInfo.user_name}}</span>
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="loginOut">
                                <i class="menu-icon el-icon-switch-button"></i>
                                <span class="menu-txt">退出登录</span>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div class="gxh" @click="openSetting">
                    <i class="el-icon-more-outline" style="transform: rotate(90deg);"></i>
                </div>
            </div>
        </div>
        <el-dialog title="切换系统" :visible.sync="dialogVisible" width="600px" append-to-body>
            <el-row :gutter="12">
                <el-col :span="8" v-for="(item, index) in appList" :key="index">
                    <div @click="changeApp(item)" style="position: relative;" v-if="item.name !== currentApp.name">
                        <el-card shadow="hover" class="app-card">
                            <i v-if="item.icon" :class="item.icon"></i>&nbsp;
                            <span>{{item.title}}</span>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>
        <slot></slot>
    </div>
</template>

<script>
import Breadcrumb from "@/components/Layout/Breadcrumb";
import { menuLeftOpenWidth, menuLeftShrinkWidth } from "@/config/menu";
import { fullScreen, exitScreen } from "@/utils/util.js";
import { buildRoute } from "@/utils/buildRoute"
import { mapState } from "vuex";

export default {
    name: "TopBar",
    components: {
        Breadcrumb,
    },
    inject: ["reload"],
    computed: {
        ...mapState({
            userInfo: (state) => state.user.user.info,
            setting: (state) => state.setting.setting,
            menu: (state) => state.menu,
        }),
        topBarWidth() {
            return this.menuOpen
                ? `calc(100% - ${menuLeftOpenWidth})`
                : `calc(100% - ${menuLeftShrinkWidth})`;
        },
        menuOpen() {
            return this.$store.state.menu.menuOpen;
        },
    },
    watch: {
        "setting.menuButton"(show) {
            this.showMenuButton = show;
        },
        "setting.showRefreshButton"(show) {
            this.showRefreshButton = show;
        },
        "setting.showCrumbs"(show) {
            this.showCrumbs = show;
        },
        "setting.showLanguage": {
            handler(show) {
                this.showLanguage = show;
            },
            immediate: true,
        },
        "menu.appList": {
            handler(list) {
                if (list && list.length) {
                    this.appList = list;
                };
            },
            immediate: true,
        },
        "menu.menuList": {
            handler(list) {
                if (list && list.id) {
                    this.currentApp = list;
                };
            },
            immediate: true,
        },
    },
    data() {
        return {
            showMenuButton: "",
            showRefreshButton: "",
            showCrumbs: "",
            isFullScreen: false,
            showNotice: false,
            showLanguage: true,
            userState: null,
            appList: [],
            currentApp: {},
            dialogVisible: false,
            headerImg: require("@img/avatar/avatar.png"),
        };
    },
    created() {
    },
    mounted() {
        this.initSetting();
        document.addEventListener("click", this.bodyCloseNotice);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.bodyCloseNotice);
    },
    methods: {
        // 初始化个性设置
        initSetting() {
            let { menuButton, showRefreshButton, showCrumbs } = this.setting;
            this.showMenuButton = menuButton;
            this.showRefreshButton = showRefreshButton;
            this.showCrumbs = showCrumbs;
        },
        // 退出登录
        loginOut() {
            // document.getElementsByTagName("html")[0].removeAttribute("class"); // 移除暗黑主题
            this.$store.dispatch("user/setLoginStatus", false);
            this.$store.dispatch("user/setUserInfo", { user_info: {} })
            this.$store.dispatch("worktab/worktabReset");
            this.$router.push("/login");
        },
        // 全屏
        fullScreen() {
            fullScreen();
            this.isFullScreen = true;
        },
        // 退出全屏
        exitScreen() {
            exitScreen();
            this.isFullScreen = false;
        },
        // 左侧菜单展开|缩小
        visibleMenu() {
            this.$store.commit("menu/setMenuOpen", !this.menuOpen);
        },
        // 打开个性化设置
        openSetting() {
            this.$emit("openSetting");
        },
        // 跳转页面
        goPage(path) {
            if (path == "loginOut") {
                this.loginOut();
                return;
            }
            this.$router.push({ path });
        },
        visibleNotice() {
            if (!this.showNotice) {
                this.showNoticeFunc(true);
            } else {
                this.showNoticeFunc(false);
            }

            setTimeout(() => {
                this.showNotice = !this.showNotice;
            }, 50);
        },
        bodyCloseNotice(e) {
            if (
                this.showNotice &&
                e.target.className.indexOf("notice-btn") === -1
            ) {
                this.showNotice = false;
                this.showNoticeFunc(false);
            }
        },
        showNoticeFunc(show) {
            this.$refs.notice.showNoticeFunc(show);
        },
        changeApp(app) {
            let { path } = this.$route;
            let router = this.$router;
            if (app.name == this.currentApp.name) return;
            this.$store.dispatch("setting/setCurrentApp", app.name);
            let sys = JSON.parse(localStorage.getItem("sys"));
            sys.user.setting.currentApp = app.name;
            localStorage.setItem("sys", JSON.stringify(sys))
            buildRoute([app])
            this.$store.dispatch("worktab/worktabRemoveAll", { path, router });

            this.$router.push({ path: '/' });
            this.dialogVisible = false;
        }
    },
};
</script>

<style lang="scss" scoped>
.app-card {
    height: 80px;
    font-size: 16px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}
$hover-color: #f8f8f8;

.topbar {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
    background: $default-background;
    background: transparent !important;

    .menu {
        height: 60px;
        line-height: 60px;
        background: $theme-menu-color;
        box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;

        .left {
            line-height: 60px;

            .svg-icon {
                width: 25px;
                vertical-align: -0.15em;
                fill: currentColor;
                overflow: hidden;
                padding-left: 15px;
                display: none;
            }

            i {
                display: inline-block;
                width: 50px;
                height: 60px;
                line-height: 60px;
                text-align: center;
                cursor: pointer;
                transition: all 0.2s;
                font-size: 16px;

                &:hover {
                    background: $hover-color;
                }
            }

            .el-route {
                line-height: 60px;
                margin-left: 10px;
            }
        }

        .right {
            display: flex;
            .change-app {
                cursor: pointer;
                font-size: 15px;
                color: #606266;
                margin-right: 16px;
                > span {
                    margin-right: 4px;
                }
            }
            .screen {
                cursor: pointer;
                padding: 0 15px;
                transition: background-color 0.3s;
                display: flex;
                align-items: center;

                i {
                    color: #777;
                    font-size: 20px;
                }

                &:hover {
                    background: $hover-color;
                }
            }

            .notice {
                cursor: pointer;
                padding: 0 15px;
                transition: background-color 0.3s;
                position: relative;

                i {
                    color: #777;
                    font-size: 17px;
                }

                .count {
                    display: block;
                    width: 6px;
                    height: 6px;
                    background: red;
                    border-radius: 50%;
                    position: absolute;
                    top: 20px;
                    right: 15px;
                }

                &:hover {
                    background: $hover-color;
                }
            }

            .user {
                height: 60px;
                line-height: 60px;
                display: flex;
                padding: 0 10px;
                transition: background-color 0.3s;

                &:hover {
                    background: $hover-color;
                }

                &:hover ul {
                    height: 80px;
                }

                .cover {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #eee;
                    margin: 15px 10px 0 0;
                    overflow: hidden;
                    cursor: pointer;
                }

                .name {
                    font-size: 13px;
                    cursor: pointer;
                }
            }

            .lang {
                height: 60px;
                line-height: 60px;
                display: flex;
                text-align: center;
                transition: background-color 0.3s;

                .btn {
                    width: 45px;
                }

                &:hover {
                    background: $hover-color;
                }

                &:hover ul {
                    height: 80px;
                }
            }

            .gxh {
                width: 45px;
                height: 60px;
                line-height: 60px;
                text-align: center;
                cursor: pointer;
                transition: background-color 0.3s;

                i {
                    font-size: 16px;
                }

                &:hover {
                    background: #f8f8f8;
                }
            }
        }
    }
}

@media only screen and (max-width: $device-ipad) {
    .topbar {
        width: 100% !important;

        .menu {
            .left {
                .svg-icon {
                    display: block;
                }
            }

            .right {
                .screen {
                    display: none;
                }

                .user {
                    padding-right: 0;

                    .name {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>