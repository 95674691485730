/*
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-09-01 13:35:16
 * @LastEditors: llg
 * @LastEditTime: 2022-09-01 13:35:51
 */
import { doPost, doRequest, doGet, post } from '../index';

// export const chatCommonReply = data => doGet('/business/ChatCommonReply')(data)
// export const commonreply = data => doPost('/business/ChatCommonReply/ChatCommonReply')(data)
export const printsourcelist = (method, data) => doRequest('/chatapi/business/ChatUser/printsourcelist')(method, data)
export const chatlog = (method, data) => doRequest('/chatapi/business/ChatLog/chatlog')(method, data)
export const chatlogpage = (method, data) => doRequest('/chatapi/business/ChatLog/chatloghistory')(method, data)

export const commonreply = (method, data) => doRequest('/chatapi/business/ChatCommonReply/commonreply')(method, data)
// 获取订单
export const getOrderList = (method, data) => doRequest('/chatapi/business/ChatUser/orderlist')(method, data)

export const statuslabel = (method, data) => doRequest('/chatapi/business/ChatUser/statuslabel')(method, data)
export const changeChatUser = (method, data) => doRequest('/chatapi/business/ChatUser/chatuser')(method, data)
export const getCustomer = (method, data) => doRequest('/chatapi/business/ChatUser/customer')(method, data)
export const transfer = (method, data) => doRequest('/chatapi/business/ChatTransfer/transfer')(method, data)
export const canchat = (method, data) => doRequest('/chatapi/business/ChatUser/canchat')(method, data)
export const transferpage = (method, data) => doRequest('/chatapi/business/ChatTransfer/transferpage')(method, data)
export const userrobotchatlog = (method, data) => doRequest('/chatapi/business/ChatLog/userrobotchatlog')(method, data)

// 快捷回复


// --------------------------------------------------------------

// 导出客服记录
export const exportChatlog = data => post('/api/v2/chatlog/exportChatlog')(data)

// 导出客服评价
export const exportChatlogEvaluate = data => post('/api/v2/chatlog/exportChatlogEvaluate')(data)

// 导出客服平均回复时长
export const exportChatlogAverageResponse = data => post('/api/v2/chatlog/exportChatlogAverageResponse')(data)