<!--
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-09-22 16:30:45
 * @LastEditors: llg
 * @LastEditTime: 2022-10-11 17:31:40
-->
<template>
    <div class="breadcrumb">
        <!-- <ul>
            <li v-for="(item, index) in breadList" :key="index">
                <span>{{ item.meta.title}}</span>
                <i v-if="index + 1 !== breadList.length">/</i>
            </li>
        </ul> -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="breadList.length - 1 == index ? null : { path: item.path }" v-for="(item, index) in breadList" :key="index">{{item.meta.title}}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
import { permissionRoutes } from '@/utils/buildRoute';
export default {
    name: "Breadcrumb",
    data() {
        return {
            breadList: [],
            parentRoute: ''
        };
    },
    watch: {
        $route() {
            this.getBreadcrumb();
        }
    },
    created() {
        this.getBreadcrumb();
    },
    methods: {
        isHome(route) {
            return route.name === "/";
        },
        getParentRoute(routes, parentRouteName) {
            for (let i = 0; i < routes.length; i++) {
                if (routes[i].name == parentRouteName) {
                    return routes[i];
                }
                if (routes[i].children && routes[i].children.length) {
                    this.parentRoute = this.getParentRoute(routes[i].children, parentRouteName);
                }
            }
            return this.parentRoute;
        },
        getBreadcrumb() {
            let matched = this.$route.matched;
            let list = [];
            //如果不是首页
            if (!this.isHome(matched[0])) {
                matched.map((item, index) => {
                    let { path, meta, name } = item;
                    if (meta.isDetail) {
                        let parentRoute = this.getParentRoute(permissionRoutes, this.getParentRouteName(name));
                        if (parentRoute) list.push({ path: parentRoute.path, meta: parentRoute.meta });
                    }
                    if (index > 0) list.push({ path, meta });
                });
            }
            this.breadList = list;
        },
        getParentRouteName(routeName) {
            if (!routeName) return;
            let parentRouteName = routeName.split('.').slice(0, -1).join('.');
            return parentRouteName
        }
    }
};
</script>

<style lang="scss" scoped>
.breadcrumb {
    margin-left: 24px;
    display: flex;
    align-items: center;

    ul {
        display: flex;

        li {
            color: #999;
            font-size: 13px;

            span {
                font-size: 13px;
            }

            i {
                font-size: 13px;
                margin: 0 7px;
            }
        }
    }
}

@media only screen and (max-width: $device-ipad) {
    .breadcrumb {
        display: none;
    }
}
</style>
