/*
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-08-04 15:37:35
 * @LastEditors: llg
 * @LastEditTime: 2022-08-05 11:40:54
 */
import { doPost, doUpload } from '../index'
// 省市区
export const getSysAreaList = data => doPost('/api/v1/sysArea/getSysAreaList')(data);
// 新增省市区
export const addSysArea = data => doPost('/api/v1/sysArea/addSysArea')(data);
// 编辑省市区
export const editSysArea = data => doPost('/api/v1/sysArea/editSysArea')(data);
// 删除省市区
export const deleteSysArea = data => doPost('/api/v1/sysArea/deleteSysArea')(data);
// 快递列表
export const getExpressCompanyList = data => doPost('/api/v1/expressCompany/getExpressCompanyList')(data);
// 获取省市区按level
export const getAllSysAreaList = data => doPost('/api/v1/sysArea/getAllSysAreaList')(data);
// 获取单个城市运费
export const getSysAreaInfoByAreaId = data => doPost('/api/v1/sysArea/getSysAreaInfoByAreaId')(data)
// 上传图片
export const uploadImg = data => doUpload('/api/v1/uploadFile/uploadImg')(data)