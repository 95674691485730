<template>
    <div class="chat-user">
        <div class="title">
            当前会话
        </div>
        <div style="height: calc(100% - 39px);overflow: auto;">
            <div class="chat-list" v-for="(item, index) in chatlogs" :key="index" :class="{'active': index == currentTab}" @click="selectUser(item, index)">
                <div v-if="currentGroup.id == item.source">
                    <div class="logo">
                        <el-avatar icon="el-icon-user-solid"></el-avatar>
                    </div>
                    <div class="list">
                        <div>
                            <span>{{item.sender}}</span>
                            <span class="time">{{item.chatLogSendTime}}</span>
                        </div>
                        <div style="position: relative;">
                            <span>{{item.chatMessageType == 13 || item.chatLogType == 2 ? '[图片]' : item.chatLogContent}}</span>
                            <span style="position: absolute;right: 0;">
                                <el-badge :value="item.unReadCount" class="item" v-if="item.unReadCount !=0">
                                </el-badge>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        chatlogs: {
            type: Array,
            default: []
        },
        currentGroup: {
            type: Object,
            default: {}
        }
    },
    components: {
    },
    data() {
        return {
            currentTab: null,
            currentUser: {}
        };
    },

    methods: {
        selectUser(user, index) {
            this.currentTab = index;
            this.currentUser = user;
            user.unReadCount = 0;
            this.$emit('getCurrentUser', this.currentUser)
        }
    },
};
</script>
<style lang="scss" scoped>
.chat-user {
    width: 22%;
    display: inline-block;
    border-right: 1px solid #e8e8e8;
    background: #fff;
    box-shadow: 0px 0px 7px 3px rgba(62, 62, 62, 0.05);
    user-select: none;
    :deep(.el-badge__content) {
        height: 16px;
        line-height: 16px;
        background: red;
        padding: 0 5px;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .title {
        border-bottom: 1px solid #e8e8e8;
        height: 38px;
        display: flex;
        justify-content: left;
        padding-left: 12px;
        align-items: center;
    }
    .chat-list {
        display: flex;
        align-items: center;
        &.active {
            background: #a8f0ff;
        }
        > div {
            display: flex;
            align-items: center;
            width: calc(100% - 12px);
            height: 40px;
            border-bottom: 1px solid #e8e8e8;
            padding: 6px;
            .logo {
                width: 36px;
                height: 36px;
                padding: 0 12px 0 6px;
                :deep(.el-avatar) {
                    width: 36px;
                    height: 36px;
                    line-height: 36px;
                }
            }
            .list {
                cursor: pointer;
                width: calc(100% - 60px);
                padding-right: 4px;
                > div {
                    display: flex;
                    justify-content: space-between;
                    color: #696969;
                }
                >div:nth-child(1) {
                    margin-bottom: 4px;
                }
                > div:nth-child(2) {
                  
                    span:nth-child(1) {
                        max-width: 240px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                }
                .time {
                    color: #c8c8c8;
                }
            }
        }
    }
}
</style>
