<template>
    <div class="chat-receive-notification" ref="notification">
        <div>
            <div style="margin-top:12px">
                <i class="el-icon-loading" style="font-size:24px;margin-bottom:12px"></i>
            </div>
            <div>{{data.show_msg}}</div>
            <div style="margin-top:12px">
                <div style="text-align:left;margin-bottom:6px">拒绝原因：</div>
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="请输入拒绝原因" v-model="reason">
                </el-input>
            </div>
            <div class="btn">
                <el-button type="danger" @click="handle(false)" :disabled="!reason">拒绝</el-button>
                <el-button type="primary" @click="handle(true)">同意</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { sendMessage } from '@/utils/websocket';
export default {
    props: {

    },
    components: {
    },
    data() {
        return {
            data: {},
            reason: ''
        };
    },

    methods: {
        handle(status) {
            let message = {
                ChatMessageType: 602,
                ChatLogContent: {
                    text_content: { id: this.data.id, submit_flag: status, refuse_reason: this.reason },
                }
            };
            sendMessage(message)
            this.closeModal();
        },
        openModal(item) {
            this.reason = '';
            this.$refs.notification.style.border = '1px solid #ddd';
            this.$refs.notification.style.height = 'auto';
            this.$refs.notification.style.maxHeight = '300px';
            this.data = item;
        },
        closeModal() {
            // this.$refs.notification.style.height = '0px';
            this.$refs.notification.style.border = '1px solid transparent';
            this.$refs.notification.style.maxHeight = '0px';
        }
    },
};
</script>
<style lang="scss" scoped>
.chat-receive-notification {
    transition: all 0.5s ease-out;
    position: fixed;
    right: 0px;
    bottom: -2px;
    width: 380px;
    height: 0px;
    max-height: 0;
    z-index: 99999;
    background: #fff;
    border-radius: 6px 6px 0 0;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1);
    > div {
        padding: 12px;
        text-align: center;
    }
    .btn {
        margin-top: 14px;
        > * {
            margin: 0 24px;
        }
    }
}
</style>
