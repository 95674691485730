/*
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-08-04 11:16:04
 * @LastEditors: llg
 * @LastEditTime: 2022-08-04 11:39:25
 */
import { getDictOption } from "@/api/phpApi/dictApi";
import http from "@/utils/request";
import { dictCacheMap } from '66in-components';

const dictService = {
    getDictByType: async (params) => {
        if (!params.type) return [];
        if (dictCacheMap.has(params.type)) {
            return Promise.resolve(buildDict(dictCacheMap.get(params.type)));
        } else {
            const res = await getDictOption({ value_list: [params.type] });
            const dictOption = buildDict(res.data.list[params.type]);
            dictCacheMap.set(params.type, res.data.list[params.type]);
            return Promise.resolve(dictOption);
        }
    },

    getDictByUrl: async (requestUrl) => {
        if (!requestUrl) return [];
        if (dictCacheMap.has(requestUrl)) {
            return Promise.resolve(dictCacheMap.get(requestUrl));
        } else {
            const res = await http.post({
                url: requestUrl,
                data: { page: 1, limit: 9999 }
            });
            dictCacheMap.set(requestUrl, res.data.list);
            return Promise.resolve(res.data.list);
        }
    }
}

function buildDict(dict) {
    let obj = {};
    dict.forEach(v => {
        if (!obj[v.value]) {
            obj[v.value] = v.label_list;
        }
    });
    return obj;
}


export default dictService;