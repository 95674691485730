/*
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-03-22 14:14:51
 * @LastEditors: llg
 * @LastEditTime: 2022-03-29 10:15:46
 */
import axios from '@/utils/request';

export function getBoxList(params) {
    return axios.post({
        url: '/api/v1/businessBox/getBoxList',
        data: params
    }).then((res) => {
        return res
    })
}

export function deleteBox(params) {
    return axios.post({
        url: '/api/v1/businessBox/deleteBox',
        data: params
    }).then((res) => {
        return res
    })
}

export function addBox(params) {
    return axios.post({
        url: '/api/v1/businessBox/addBox',
        data: params
    }).then((res) => {
        return res
    })
}

export function editBox(params) {
    return axios.post({
        url: '/api/v1/businessBox/editBox',
        data: params
    }).then((res) => {
        return res
    })
}

export function getBoxInfo(params) {
    return axios.post({
        url: '/api/v1/businessBox/getBoxInfo',
        data: params
    }).then((res) => {
        return res
    })
}

export function getBoxPrinter(params) {
    return axios.post({
        url: '/api/v1/businessBox/getBoxPrinter',
        data: params
    }).then((res) => {
        return res
    })
}

export function editBoxPrint(params) {
    return axios.post({
        url: '/api/v1/businessBox/editBoxPrint',
        data: params
    }).then((res) => {
        return res
    })
}

export function editBoxPrintPrice(params) {
    return axios.post({
        url: '/api/v1/businessBox/editBoxPrintPrice',
        data: params
    }).then((res) => {
        return res
    })
}

export function getBoxOrderList(params) {
    return axios.post({
        url: '/api/v1/boxOrder/getBoxOrderList',
        data: params
    }).then((res) => {
        return res
    })
}

export function getBoxMeterReadingList(params) {
    return axios.post({
        url: '/api/v1/boxMeterReading/getBoxMeterReadingList',
        data: params
    }).then((res) => {
        return res
    })
}

export function getBoxMeterReadingListGroupByMonth(params) {
    return axios.post({
        url: '/api/v1/boxMeterReading/getBoxMeterReadingListGroupByMonth',
        data: params
    }).then((res) => {
        return res
    })
}

export function getFeedbackList(params) {
    return axios.post({
        url: '/api/v1/feedback/getFeedbackList',
        data: params
    }).then((res) => {
        return res
    })
}

export function getNoticeMessageList(params) {
    return axios.post({
        url: '/api/v1/noticeMessage/getNoticeMessageList',
        data: params
    }).then((res) => {
        return res
    })
}

export function addNoticeMessage(params) {
    return axios.post({
        url: '/api/v1/noticeMessage/addNoticeMessage',
        data: params
    }).then((res) => {
        return res
    })
}

export function addBoxPrinterModel(params) {
    return axios.post({
        url: '/api/v1/boxPrinterModel/addBoxPrinterModel',
        data: params
    }).then((res) => {
        return res
    })
}

export function getBoxPrinterModelList(params) {
    return axios.post({
        url: '/api/v1/boxPrinterModel/getBoxPrinterModelList',
        data: params
    }).then((res) => {
        return res
    })
}

export function deleteBoxPrinterModel(params) {
    return axios.post({
        url: '/api/v1/boxPrinterModel/deleteBoxPrinterModel',
        data: params
    }).then((res) => {
        return res
    })
}

export function getBoxErrorLogList(params) {
    return axios.post({
        url: '/api/v2/boxError/getBoxErrorLogList',
        data: params,
        header: {
            version: 'v2'
        }
    }).then((res) => {
        return res
    })
}

export function editBoxErrorLog(params) {
    return axios.post({
        url: '/api/v2/boxError/editBoxErrorLog',
        data: params,
        header: {
            version: 'v2'
        }
    }).then((res) => {
        return res
    })
}

export function getErrorInfo(params) {
    return axios.post({
        url: '/api/v2/boxError/getErrorInfo',
        data: params,
        header: {
            version: 'v2'
        }
    }).then((res) => {
        return res
    })
}

export function getOrderDetailList(params) {
    return axios.post({
        url: '/api/v1/boxOrder/getOrderDetailList',
        data: params
    }).then((res) => {
        return res
    })
}

export function getOrderInfo(params) {
    return axios.post({
        url: '/api/v1/boxOrder/getOrderInfo',
        data: params
    }).then((res) => {
        return res
    })
}

export function printOrderDetail(params) {
    return axios.post({
        url: '/api/v1/boxOrder/printOrderDetail',
        data: params
    }).then((res) => {
        return res
    })
}

export function getOperateLogList(params) {
    return axios.post({
        url: '/api/v1/operateLog/getOperateLogList',
        data: params
    }).then((res) => {
        return res
    })
}

export function exportOrderList(params) {
    return axios.post({
        url: '/api/v1/boxOrder/exportOrderList',
        data: params
    }).then((res) => {
        return res
    })
}

export function exportBoxList(params) {
    return axios.post({
        url: '/api/v1/businessBox/exportBoxList',
        data: params
    }).then((res) => {
        return res
    })
}

export function exportBoxErrorLogList(params) {
    return axios.post({
        url: '/api/v2/boxError/exportBoxErrorLogList',
        data: params,
        header: {
            version: 'v2'
        }
    }).then((res) => {
        return res
    })
}

export function exportBoxMeterReadingList(params) {
    return axios.post({
        url: '/api/v1/boxMeterReading/exportBoxMeterReadingList',
        data: params
    }).then((res) => {
        return res
    })
}

export function exportBoxMeterReadingListGroupByMonth(params) {
    return axios.post({
        url: '/api/v1/boxMeterReading/exportBoxMeterReadingListGroupByMonth',
        data: params
    }).then((res) => {
        return res
    })
}

export function updateBoxStatus(params) {
    return axios.post({
        url: '/api/v1/businessBox/updateBoxStatus',
        data: params
    }).then((res) => {
        return res
    })
}

export function getBoxMeterReadingInfoGroupByMonth(params) {
    return axios.post({
        url: '/api/v1/boxMeterReading/getBoxMeterReadingInfoGroupByMonth',
        data: params
    }).then((res) => {
        return res
    })
}

export function exportOrderDetailList(params) {
    return axios.post({
        url: '/api/v1/boxOrder/exportOrderDetailList',
        data: params
    }).then((res) => {
        return res
    })
}





// common
export function getPaperSizeList() {
    return axios.get({
        url: '/api/v1/common/getPaperSizeList',
    }).then((res) => {
        return res
    })
}

export function getPhotoSizeList() {
    return axios.get({
        url: '/api/v1/common/getPhotoSizeList',
    }).then((res) => {
        return res
    })
}

export function getCertificateSizeList() {
    return axios.get({
        url: '/api/v1/common/getCertificateSizeList',
    }).then((res) => {
        return res
    })
}

export function getImageSizeList() {
    return axios.get({
        url: '/api/v1/common/getImageSizeList',
    }).then((res) => {
        return res
    })
}

export function getNoticeTargetList() {
    return axios.get({
        url: '/api/v1/common/getNoticeTargetList',
    }).then((res) => {
        return res
    })
}

export function getPrinterStatusList() {
    return axios.get({
        url: '/api/v1/common/getPrinterStatusList',
    }).then((res) => {
        return res
    })
}

export function getErrorStatusList () {
    return axios.get({
        url: '/api/v1/common/getErrorStatusList',
    }).then((res) => {
        return res
    })
}

export function getOrderStatusList() {
    return axios.get({
        url: '/api/v1/common/getOrderStatusList',
    }).then((res) => {
        return res
    })
}

export function getLineStatusList() {
    return axios.get({
        url: '/api/v1/common/getLineStatusList',
    }).then((res) => {
        return res
    })
}

export function getWarmingStatusList() {
    return axios.get({
        url: '/api/v1/common/getWarmingStatusList',
    }).then((res) => {
        return res
    })
}