import { post } from '@/api/index'
import { doUpload } from '@/api'

import axios from '@/utils/request';

export function getCustOrderPageList(params) {
    return axios.post({
        url: '/api/CustOrder/GetCustOrderPageList',
        data: params
    }).then((res) => {
        return res
    })
}

export function getBusinessSettlerPageList(params) {
    return axios.post({
        url: '/api/CustOrder/GetBusinessSettlerPageList',
        data: params
    }).then((res) => {
        return res
    })
}

export function setBusinessSettler(params) {
    return axios.post({
        url: '/api/CustOrder/SetBusinessSettler',
        data: params
    }).then((res) => {
        return res
    })
}

export function getOrderDetailById(id) {
    return axios.get({
        url: '/api/CustOrder/GetOrderDetailById/' + id,
    }).then((res) => {
        return res
    })
}

export function getOrderDetailListByOrderId(params) {
    return axios.post({
        url: '/api/CustOrder/GetOrderDetailListByOrderId',
        data: params
    }).then((res) => {
        return res
    })
}

export function downloadOrder(params) {
    return axios.post({
        url: '/api/CustOrder/DownloadOrder',
        data: params,
    }).then((res) => {
        return res
    })
}

export function wpsDownloadOrderFile(id, params) {
    return axios.get({
        url: '/api/CustOrder/WpsDownloadOrderFile/' + id,
        data: params,
    }).then((res) => {
        return res
    })
}

export function orderDetailRefund(data) {
    return axios.post({
        url: '/api/CustOrder/OrderDetailRefund',
        data: data,
    })
}

export function downloadOrderFileStream(id, params) {
    return axios.get({
        url: '/api/CustOrder/DownloadOrderFileStream/' + id,
        data: params
    }).then((res) => {
        return res
    })
}

export function batchDownloadOrder(params) {
    return axios.post({
        url: '/api/CustOrder/BatchDownloadOrder',
        data: params
    }).then((res) => {
        return res
    })
}

export function rebuild(params) {
    return axios.post({
        url: '/api/CustOrder/Rebuild',
        data: params
    }).then((res) => {
        return res
    })
}

export function manufactureOrder(id) {
    return axios.get({
        url: '/api/CustOrder/ManufactureOrder/' + id,
    }).then((res) => {
        return res
    })
}

export function deliveryOrder(params) {
    return axios.post({
        url: '/api/CustOrder/DeliveryOrder',
        data: params
    }).then((res) => {
        return res
    })
}

export function completeOrder(id) {
    return axios.get({
        url: '/api/CustOrder/CompleteOrder/' + id,
    }).then((res) => {
        return res
    })
}

export function refundOrder(params) {
    return axios.post({
        url: '/api/CustOrder/RefundOrder',
        data: params
    }).then((res) => {
        return res
    })
}

export function rejectOrder(params) {
    return axios.post({
        url: '/api/CustOrder/RejectOrder',
        data: params
    }).then((res) => {
        return res
    })
}

export function refundMoney(params) {
    return axios.post({
        url: '/api/CustOrder/RefundMoney',
        data: params
    }).then((res) => {
        return res
    })
}


export function getProvinceList() {
    return axios.get({
        url: '/api/SysArea/GetProvinceList',
    }).then((res) => {
        return res
    })
}

export function getCityList(id) {
    return axios.get({
        url: '/api/SysArea/GetCityList/' + id,
    }).then((res) => {
        return res
    })
}

export function getAreaList(id) {
    return axios.get({
        url: '/api/v1/sysArea/getAreaList/' + id,
    }).then((res) => {
        return res
    })
}

export function doExportList(params) {
    return axios.post({
        url: '/api/CustOrder/DoExportList',
        data: params
    }).then((res) => {
        return res
    })
}

export function doBusinessExportList(params){
    return axios.post({
        url: '/api/CustOrder/DoBusinessExportList',
        data: params
    }).then((res) => {
        return res
    })
}

export function doOrderDetailExportList(params) {
    return axios.post({
        url: '/api/CustOrder/DoOrderDetailExportList',
        data: params
    }).then((res) => {
        return res
    })
}

export function updateOrderPage(params) {
    return axios.post({
        url: '/api/CustOrder/UpdateOrderPage',
        data: params
    }).then((res) => {
        return res
    })
}

export function getKdCustOrderDailyLogPageList(params) {
    return axios.post({
        url: '/api/DailyLog/GetKdCustOrderDailyLogPageList',
        data: params
    }).then((res) => {
        return res
    })
}

export function getKdCustOrderDailyLogSum(params) {
    return axios.post({
        url: '/api/DailyLog/GetKdCustOrderDailyLogSum',
        data: params
    }).then((res) => {
        return res
    })
}

export function getOrderDetailInfoById(id) {
    return axios.get({
        url: '/api/CustOrder/GetOrderDetailInfoById/' + id,
    }).then((res) => {
        return res
    })
}

export function updateOrderPrice(params) {
    return axios.post({
        url: '/api/CustOrder/UpdateOrderPrice',
        data: params
    }).then((res) => {
        return res
    })
}

export function getWpsCustOrderDailyLogPageList(params) {
    return axios.post({
        url: '/api/DailyLog/GetWpsCustOrderDailyLogPageList',
        data: params
    }).then((res) => {
        return res
    })
}

export function getWpsCustOrderDailyLogSum(params) {
    return axios.post({
        url: '/api/DailyLog/GetWpsCustOrderDailyLogSum',
        data: params
    }).then((res) => {
        return res
    })
}

export function doKdExportList(params) {
    return axios.get({
        url: '/api/DailyLog/DoKdExportList',
        data: params
    }).then((res) => {
        return res
    })
}

export function doWpsExportList(params) {
    return axios.get({
        url: '/api/DailyLog/DoWpsExportList',
        data: params
    }).then((res) => {
        return res
    })
}

export function batchSettle(params) {
    return axios.post({
        url: '/api/CustOrder/BatchSettle',
        data: params
    }).then((res) => {
        return res
    })
}

// 商家日报
export function getBusinessKdCustOrderDailyLogPageList(params) {
    return axios.post({
        url: '/api/DailyLog/GetBusinessKdCustOrderDailyLogPageList',
        data: params
    }).then((res) => {
        return res
    })
}

export function getBusinessKdCustOrderDailyLogSum(params) {
    return axios.post({
        url: '/api/DailyLog/GetBusinessKdCustOrderDailyLogSum',
        data: params
    }).then((res) => {
        return res
    })
}

export function doBusinessKdExportList(params) {
    return axios.get({
        url: '/api/DailyLog/DoBusinessKdExportList',
        data: params
    }).then((res) => {
        return res
    })
}

export function getBusinessWpsCustOrderDailyLogPageList(params) {
    return axios.post({
        url: '/api/DailyLog/GetBusinessWpsCustOrderDailyLogPageList',
        data: params
    }).then((res) => {
        return res
    })
}

export function getBusinessWpsCustOrderDailyLogSum(params) {
    return axios.post({
        url: '/api/DailyLog/GetBusinessWpsCustOrderDailyLogSum',
        data: params
    }).then((res) => {
        return res
    })
}

export function doBusinessWpsExportList(params) {
    return axios.get({
        url: '/api/DailyLog/DoBusinessWpsExportList',
        data: params
    }).then((res) => {
        return res
    })
}

export function getIflytekAppointmentOrderPageList(params) {
    return axios.post({
        url: '/api/IflytekAppointment/GetIflytekAppointmentOrderPageList',
        data: params
    }).then((res) => {
        return res
    })
}

export function posted(id) {
    return axios.get({
        url: '/api/IflytekAppointment/Posted/' + id,
    }).then((res) => {
        return res
    })
}

export function delayed() {
    return axios.post({
        url: '/api/IflytekAppointment/Delayed',
    }).then((res) => {
        return res
    })
}

export function reject(id) {
    return axios.get({
        url: '/api/IflytekAppointment/Reject/' + id,
    }).then((res) => {
        return res
    })
}

export function addOrderOperationLog(params) {
    return axios.post({
        url: '/api/SysOrderOperationLog/AddOrderOperationLog',
        data: params
    }).then((res) => {
        return res
    })
}

export function getOrderOperationLogByOrderId(id) {
    return axios.get({
        url: '/api/SysOrderOperationLog/getOrderOperationLogByOrderId/' + id,
    }).then((res) => {
        return res
    })
}

export function doImportSettleList() {
    return axios.get({
        url: '/api/CustOrder/DoImportSettleList',
    }).then((res) => {
        return res
    })
}

export function getKdOrderDailyLogSum(data) {
    return axios.post({
        url: '/api/DailyLog/GetKdCustOrderDailyLogSum',
        data
    })
}

export function getBusinessKdOrderDailyLogSum(data) {
    return axios.post({
        url: '/api/DailyLog/GetBusinessKdCustOrderDailyLogSum',
        data
    })
}

export function getWpsOrderDailyLogSum(data) {
    return axios.post({
        url: '/api/DailyLog/GetWpsCustOrderDailyLogSum',
        data
    })
}

export function getBusinessWpsOrderDailyLogSum(data) {
    return axios.post({
        url: '/api/DailyLog/GetBusinessWpsCustOrderDailyLogSum',
        data
    })
}

export function batchManufactureOrder(params) {
    return axios.post({
        url: '/api/CustOrder/BatchManufactureOrder',
        data: params
    }).then((res) => {
        return res
    })
}

export function batchCompleteOrder(params) {
    return axios.post({
        url: '/api/CustOrder/BatchCompleteOrder',
        data: params
    }).then((res) => {
        return res
    })
}

export function coverDownload(id) {
    return axios.get({
        url: '/api/CustOrder/CoverDownload/' + id,
    }).then((res) => {
        return res
    })
}

// 新报备
export function getIflyAppointmentList(params) {
    return axios.post({
        url: '/api/v1/appointment/getIflyAppointmentList',
        data: params
    }).then((res) => {
        return res
    })
}

export function refuseIflyAppointment(params) {
    return axios.post({
        url: '/api/v1/appointment/refuseIflyAppointment',
        data: params
    }).then((res) => {
        return res
    })
}

export function delayIflyAppointment(params) {
    return axios.post({
        url: '/api/v1/appointment/delayIflyAppointment',
        data: params
    }).then((res) => {
        return res
    })
}

export function sendIflyAppointment(params) {
    return axios.post({
        url: '/api/v1/appointment/sendIflyAppointment',
        data: params
    }).then((res) => {
        return res
    })
}

// 加密订单文件
export const encrptyDownloadOrderFile = data => post('/api/v1/order/encrptyDownloadOrderFile')(data)
// 获取科大学校列表
export const getIflySchoolList = data => post('/api/v1/iflySchool/getIflySchoolList')(data)
// 导入科大学校列表
export const importIflySchoolRobotUrl = data => doUpload('/api/v1/iflySchool/importIflySchoolRobotUrl')(data)
// 导出科大学校列表
export const exportIflySchoolTask = data => post('/api/v1/iflySchool/exportIflySchoolTask')(data)
// 更新科大学校列表
export const updateIflySchool = data => post('/api/v1/iflySchool/updateIflySchool')(data)

