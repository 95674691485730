<!--
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-02-28 11:36:11
 * @LastEditors: llg
 * @LastEditTime: 2022-10-11 17:52:21
-->
<template>
    <div class="page-content" style="padding-right:16px !important;padding-top:4px" v-if="isReload">
        <el-collapse v-model="activeNames">
            <el-collapse-item :name="index" v-for="(item, index) in menuList" :key="index">
                <div slot="title">
                    <i :class="item.icon || 'el-icon-tickets'" style="font-size:18px;"></i>&nbsp;
                    <span style="font-size:18px;">{{item.title}}</span>
                </div>
                <el-row :gutter="12">
                    <div v-for="(_item, _index) in item.children" :key="_index">
                        <el-col :xs="12" :sm="12" :lg="3" v-if="!_item.meta.isDetail">
                            <div @click="goPage(_item)">
                                <el-card shadow="hover" style="height: 80px;font-size: 16px;display: flex;cursor: pointer;align-items: center;justify-content: center;">
                                    <div>
                                        <i :class="_item.icon || 'el-icon-tickets'" style="font-size:18px;"></i>&nbsp;
                                        <span>{{_item.title}}</span>
                                    </div>
                                </el-card>
                            </div>
                        </el-col>
                    </div>
                </el-row>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState({
            menu: (state) => state.menu,
        }),
    },
    data() {
        return {
            activeNames: [],
            menuList: [],
            isReload: true
        };
    },
    methods: {
        goPage(item) {
            this.$router.push({ path: item.path });
        },
        reload() {
            this.isReload = false;
            this.$nextTick(() => {
                this.isReload = true;
            })
        },
        // updateArray(array) {
        //     const newArray = JSON.parse(JSON.stringify(array));
        //     for (let i = 0; i < newArray.length; i ++) {
        //         if (newArray[i].children) {
        //             for (let j = 0; j < newArray[i].children.length; j ++) {
        //                 if (newArray[i].children[j].children) {
        //                     for (let d = 0; d < newArray[i].children[j].children.length; d ++) {
        //                         if (newArray[i].children[j].children[d].nav_type ==3) {
        //                             newArray[i].children.push(newArray[i].children[j].children[d]);
        //                         }
        //                     }
        //                 }
        //                 if (newArray[i].children[j].nav_type ==2 && newArray[i].children[j].level ==2) {
        //                     newArray[i].children.splice(j, 1);
        //                     j --;
        //                 }
        //             };
        //         }
        //     };
        //     return newArray;
        // }
    },
    watch: {
        "menu.menuList": {
            handler(list) {
                if (list && list.id) {
                    this.menuList = list.children;
                    // console.log(this.menuList = this.updateArray(list.children))
                    for (let i = 0; i < this.menuList.length; i++) {
                        this.activeNames.push(i);
                    }
                }
            },
            immediate: true,
        },
    },
    created() {
    }
};
</script>

<style lang="scss" scoped>
.page-content {
    ::v-deep .el-collapse {
        border-top: 0;
        .el-collapse-item {
            .el-collapse-item__header {
                font-size: 16px;
                // border-bottom: 1px solid #ebeef5;
                margin-bottom: 4px;
                margin-top: 4px;
            }
        }
        .el-collapse-item:last-child {
            .el-collapse-item__wrap {
                border-bottom: 0;
            }
        }
        .el-collapse-item__content {
            padding-bottom: 0;
            .el-col {
                padding-bottom: 25px;
            }
        }
    }
    :deep(.el-card__body) {
        width: 100%;
        padding: 0;
        > div {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: calc(100% - 48px);
            text-align: center;
            padding: 24px;
        }
    }
}
::v-deep .el-collapse {
    border-bottom: 0 !important;
}
</style>