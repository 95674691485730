export const listView =  {
    data: [],
    selection: false,
    loading: false,
    height: '460px',
    operation: {
        open: false,
        width: 160,
        fixed: 'right'
    },
    columns: [
        {
            prop: 'transferSenderName',
            label: '发起人',
        },
        {
            prop: 'chatTransferSendTime',
            label: '发起时间',
            width: 200
        },
        {
            prop: 'receiverName',
            label: '接收者'
        },
        {
            prop: 'receiveTime',
            label: '接收时间',
            width: 200
        },
        {
            prop: 'transferStatus',
            label: '转接状态'
        },
        {
            prop: 'remark',
            label: '处理备注'
        }
    ]
}