<template>
    <div style="height:100%">
        <el-tabs v-model="tabValue">
            <el-tab-pane label="用户信息" name="first">
                <div class="first">
                    <div>
                        <div>
                            <span>用户名称：</span>
                            <span>{{currentUser.senderName}}</span>
                        </div>
                        <div v-copy="{text: currentUser.senderName }">复制</div>
                    </div>
                    <div>
                        <div>
                            <span>用户ID：</span>
                            <span>{{currentUser.senderId}}</span>
                        </div>
                        <div v-copy="{text: currentUser.senderId }">复制</div>
                    </div>
                    <div v-show="showUserOrder" style="margin-top:20px;padding:0 12px;height:calc(100vh - 254px)" class="order">
                        <div class="title">订单信息</div>
                        <el-table :data="orderData" border style="width: 100%" v-loading="orderLoading" :height="orderData.length ? 'calc(100% - 74px)' : 'auto'">
                            <el-table-column prop="cust_order_id" label="订单号">
                            </el-table-column>
                            <el-table-column prop="order_time" label="下单时间"></el-table-column>
                            <el-table-column label="操作" width="60" align="center">
                                <template slot-scope="scope">
                                    <el-button type="text" size="small">
                                        <div v-copy="{text: scope.row.cust_order_id }">复制</div>
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination style="margin-top:12px" v-if="orderData.length" small background @size-change="getOrderList" @current-change="getOrderList" :current-page="orderPageQuery.page" :page-size="orderPageQuery.limit" layout="prev, pager, next" :total="orderTotal">
                        </el-pagination>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="常用回复" name="second">
                <div class="second">
                    <div class="header">
                        <el-input size="mini" v-model="replies" placeholder="搜索相关内容" prefix-icon="el-icon-search" style="width:calc(100% - 85px);margin-right:12px"></el-input>
                        <el-button type="primary" size="mini" icon="el-icon-search" @click="searchReplies">搜索</el-button>
                    </div>
                    <div class="content">
                        <div class="common-replies" v-loading="repliesLoading">
                            <div v-for="item in isSearch ? searchRepliesData : repliesData" :key="item.id">
                                <span @click="sendMessage('edit', item)">{{ item.content }}</span>
                                <span>
                                    <el-popconfirm title="确定删除该条常用回复吗？" @confirm="deleteReplies(item)">
                                        <i class="el-icon-close" slot="reference"></i>
                                    </el-popconfirm>
                                </span>
                            </div>
                        </div>
                        <el-popover placement="right" title="常用回复" width="250" trigger="click" v-model="visible">
                            <div>
                                <el-input v-model="value" placeholder="常用回复"></el-input>
                            </div>
                            <div style="text-align: right; margin-top: 12px">
                                <el-button size="mini" type="default" @click="visible = false">取消</el-button>
                                <el-button type="primary" size="mini" @click="addReplies" :disabled="!value" v-debounce>确定</el-button>
                            </div>
                            <a class="add" slot="reference">添加常用回复</a>
                        </el-popover>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="智能回复" name="third">
                <div class="third">
                    <div class="header">
                        <div>
                            <el-select placeholder="问题分类" style="width:120px;margin-right:6px" size="mini" v-model="typeId" @change="getChatQuestionList">
                                <el-option :key="'#all#'" :label="'全部问题分类'" :value="'#all#'"></el-option>
                                <el-option v-for="item in questionType" :key="item.question_type_id" :label="item.type_name" :value="item.question_type_id"></el-option>
                            </el-select>
                            <!-- <sy-select placeholder="问题分类" style="width:120px;margin-right:6px" size="mini" v-model="typeId" @change="getChatQuestionList" :options="questionType" :props="{
                                label: 'type_name',
                                value: 'question_type_id'
                            }" /> -->
                            <el-input size="mini" v-model="question" placeholder="搜索相关内容" prefix-icon="el-icon-search" style="width:calc(100% - 126px)"></el-input>
                        </div>
                        <div class="btn">
                            <el-button type="primary" size="mini" icon="el-icon-search" @click="search()">搜索</el-button>
                            <el-button type="default" size="mini" icon="el-icon-refresh" @click="reset()">重置</el-button>
                        </div>
                    </div>
                    <div class="content" v-loading="loading">
                        <template v-for="(item, index) in questionList">
                            <div :key="index">
                                <div>
                                    <div>
                                        <span>问题分类：</span>
                                        <span>{{item.type_name}}</span>
                                    </div>
                                    <div>
                                        <span>问题问法：</span>
                                        <span>{{item.question}}</span>
                                    </div>
                                    <div>
                                        <span>问题答案：</span>
                                        <span>{{item.answer}}</span>
                                    </div>
                                </div>
                                <div>
                                    <div @click="sendMessage('send', item)">发送</div>
                                    <div @click="sendMessage('edit', item)">编辑发送</div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { getChatQuestionTypeList, getChatQuestionList, getChatCustomerCommonRepliesData, addChatCustomerCommonReplies, delChatCustomerCommonReplies, getOrderPageList } from '@/api/phpApi/chatApiV2'

export default {
    props: {
        currentUser: {
            type: Object,
            default: () => {
                return {}
            }
        },
        currentGroup: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    components: {
    },
    data() {
        return {
            visible: false,
            value: '',
            data: [],
            currentCommonreply: {},
            loading: false,
            orderLoading: false,
            repliesLoading: false,
            typeId: '#all#',
            question: '',
            replies: '',
            tabValue: 'first',
            questionType: [],
            questionList: [],
            repliesData: [],
            isSearch: false,
            searchRepliesData: [],
            showUserOrder: false,
            orderData: [],
            orderTotal: 0,
            orderPageQuery: {
                page: 1,
                limit: 10,
            }
        };
    },

    methods: {
        async getOrderList() {
            this.tabValue = 'first';
            this.showUserOrder = true;
            this.orderLoading = true;
            const [err, res] = await getOrderPageList({
                sys_user_id: this.currentUser.senderId,
                source: this.currentGroup.source
            });
            if (err) return;
            this.orderLoading = false;
            this.orderData = res.data.list;
            this.orderTotal = res.data.count;
        },
        async getChatQuestionTypeList() {
            const [err, res] = await getChatQuestionTypeList({
                page: 1,
                limit: 1000,
                source: this.currentGroup.source
            });
            if (err) return;
            this.questionType = res.data.list;
            this.typeId = '#all#';
            this.getChatQuestionList()
        },
        async getChatQuestionList() {
            this.loading = true;
            const [err, res] = await getChatQuestionList({
                page: 1,
                limit: 1000,
                source: this.currentGroup.source,
                question_type_id: this.typeId,
                question: this.question
            });
            if (err) return;
            this.questionList = res.data.list.map(v => {
                let type_name = this.questionType.find(item => item.question_type_id == v.question_type_id);
                v.type_name = type_name ? type_name.type_name : v.question_type_id;
                return v;
            });
            this.loading = false;
        },
        async getChatCustomerCommonRepliesData() {
            this.repliesLoading = true;
            const [err, res] = await getChatCustomerCommonRepliesData();
            if (err) return;
            this.repliesData = res.data;
            this.repliesLoading = false;
        },
        reset() {
            this.question = '';
            this.getChatQuestionList();
        },
        // formatType(typeId) {
        //     if (typeId == '#all#') {

        //     } else {
        //         const type = this.questionType.find(v => v.question_type_id == typeId);
        //         return type ? type.type_name : typeId
        //     }
        // },
        search() {
            this.getChatQuestionList();
            // if (this.question == '') return data;
            // return data.filter(v => v.question.indexOf(this.question) != -1)
        },
        searchReplies() {
            this.isSearch = true;
            this.searchRepliesData = this.repliesData.filter(v => v.content.indexOf(this.replies) != -1);
        },
        async addReplies() {
            const [err, res] = await addChatCustomerCommonReplies({
                content: this.value
            });
            if (err) return;
            this.getChatCustomerCommonRepliesData();
            this.visible = false;
            this.value = null;
            this.$message.success('添加成功')
        },
        async deleteReplies(dataRow) {
            const [err, res] = await delChatCustomerCommonReplies({
                id: dataRow.id
            });
            if (err) return;
            this.getChatCustomerCommonRepliesData();
            this.$message.success('删除成功')
        },
        sendMessage(type, item) {
            if (item.content) item.answer = item.content;
            this.$emit('sendReply', {
                type,
                item
            })
        }
    },
    async mounted() {
        await this.getChatCustomerCommonRepliesData();
        await this.getChatQuestionTypeList()
    }
};
</script>
<style lang="scss" scoped>
:deep(.el-tabs__nav-wrap::after) {
    height: 1px;
}
:deep(.el-tabs__nav-scroll) {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
}

.bottom {
    height: calc(100% - 68px);
}
.user-info {
    padding: 8px 12px;
    border-bottom: 1px solid #e8e8e8;
    width: calc(100% - 24px);
    .title {
        font-weight: bold;
        margin-bottom: 12px;
    }
    .info {
        > div {
            color: #666666;
            margin-bottom: 12px;
        }
        > div:last-child {
            margin-bottom: 6px;
        }
    }
}
.first {
    > div:not(.order) {
        padding: 0 12px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        font-size: 13px;
        > div {
            color: #666666;
            white-space: nowrap;
            overflow: hidden;
            width: calc(100% - 30px);
            flex-shrink: 0;
            text-overflow: ellipsis;
        }
        > div:nth-child(2) {
            color: #409eff;
            cursor: pointer;
            user-select: none;
        }
    }
    .order {
        .title {
            font-size: 15px;
            margin-bottom: 6px;
            font-weight: bold;
        }
    }
}
.third,
.second {
    .header {
        padding: 0 12px 12px 12px;
        text-align: right;
        border-bottom: 1px solid #e8e8e8;
        .btn {
            margin-top: 12px;
        }
    }
}
.second {
    .content {
        .add {
            display: block;
            text-align: left;
            padding: 0 12px;
            color: #409eff;
            margin-top: 12px;
            cursor: pointer;
        }
        .common-replies {
            max-height: calc(100vh - 270px);
            overflow: auto;
            > div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 36px;
                border-bottom: 1px solid #e8e8e8;
                cursor: pointer;
                padding: 0 12px;
                user-select: none;
                > span:nth-child(1) {
                    width: calc(100% - 16px);
                    height: 100%;
                    line-height: 36px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                i {
                    font-size: 18px;
                }
            }
        }
    }
}
.third {
    .content {
        min-height: 200px;
        max-height: calc(100vh - 268px);
        overflow: auto;
        > div {
            background: #e8e8e8;
            margin-bottom: 4px;
            padding: 6px 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            > div:first-child {
                > div {
                    display: flex;
                    margin: 4px 0;
                    > span:first-child {
                        flex-shrink: 0;
                        width: 60px;
                    }
                }
            }
            > div:last-child {
                flex-shrink: 0;
                margin-left: 12px;
                > div {
                    color: #409eff;
                    cursor: pointer;
                    margin: 12px 0;
                    text-align: center;
                }
            }
        }
    }
}
</style>
