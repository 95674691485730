<template>
    <div class="chat-transfer">
        <el-dialog title="转接" :visible.sync="visible" width="450px" :modal-append-to-body='false' append-to-body>
            <el-form :model="form" :rules="rules" ref="form" label-width="auto">
                <el-form-item label="受理产品：">
                    {{formatValue(currentUser.source)}}
                </el-form-item>
                <el-form-item label="受理客服：" prop="receiverId">
                    <el-select v-model="form.receiverId" placeholder="请选择受理客服" style="width:100%">
                        <el-option :label="item.name" :value="item.userId" v-for="item in customer" :key="item.userId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="处理备注：">
                    <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="请输入处理备注" v-model="form.remark">
                    </el-input>
                </el-form-item>
                <el-form-item label="转移聊天记录：">
                    <el-switch v-model="form.isTransferChaLog">
                    </el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="visible = false">取 消</el-button>
                <el-button v-debounce type="primary" @click="transfer">转 接</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getCustomer, transfer } from '@/api/chatApi';
export default {
    props: {
        currentUser: {
            type: Object,
            default: () => { return {} }
        },
        sourceList: {
            type: Array,
            default: () => { return [] }
        }
    },
    components: {
    },
    data() {
        return {
            visible: false,
            tipVisible: false,
            isTransfer: true,
            form: {
                receiverId: '',
                remark: '',
                isTransferChaLog: false
            },
            rules: {
                receiverId: [
                    { required: true, message: '请选择受理客服', trigger: 'change' },
                ],
            },
            customer: [],
        };
    },

    methods: {
        async transfer() {
            this.$refs['form'].validate(async (valid) => {
                let entity = Object.assign({}, this.form);
                if (entity.receiverId) {
                    let item = this.customer.find(v => { return v.userId == entity.receiverId })
                    entity.receiverName = item.name
                };
                if (valid) {
                    const res =  await transfer('post', {
                        ...entity,
                        source: this.currentUser.source,
                        userId: this.currentUser.senderId,
                        userName: this.currentUser.sender,
                        transferSenderId: this.$store.state.chat.onlineUser.userId,
                        transferSenderName: this.$store.state.chat.onlineUser.name
                    });
                    if (res.code == 0) {
                        this.visible = false;
                    }
                } else {
                    return false;
                }
            });
        },
        formatValue(sourceId) {
            if (!sourceId || !this.sourceList.length) {
                return;
            };
            let item = this.sourceList.find(v => { return v.id == sourceId });
            if (item) return item.name;
            return '未知';
        },
        async getCustomer(sourceId) {
            const res = await getCustomer('get', {
                source: sourceId
            });
            if (res.code == 0) {
                this.customer = res.data;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
:deep(.tip-modal) {
    .el-dialog__header {
        display: none;
    }
    i {
        font-size: 24px;
        margin-bottom: 12px;
    }
}
</style>

<style>
.el-notification {
    width: 230px;
    display: block;
    max-height: 130px;
    min-height: 100px;
}
</style>
