import { MessageBox } from 'element-ui';

let notificationQueue = [];
let isPlaying = false;
let audioElement = null;
// 上个执行完的任务
let previousTask = null;
// 保存有时间间隔的上个任务
let previousTaskWithInterval = null;


function audioPlay(task = {}) {
    if (!task.audio) return;
    audioElement = new Audio(task.audio);
    const playPromise = audioElement.play();
    if (playPromise) {
        playPromise.then(() => {
            // 音频播放成功
        }).catch(err => {
            MessageBox.confirm('是否授权本次访问自动播放音频?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if (audioElement) audioElement.play();
            }).catch(() => {});
        });
    }
}

function processQueue() {
    if (isPlaying || notificationQueue.length === 0) {
        return;
    }
    notificationQueue.sort((a, b) => b.priority - a.priority);
    const task = notificationQueue.shift();
    /*
        判断是否播放
        1：没有previousTask（证明是第一个任务）的时候
        2：本次task.interval等于0的时候
        3：当本次task.interval不为0，如果没有previousTaskWithInterval则播放
        4：当本次task.interval不为0，如果有previousTaskWithInterval，需要判断本次任务的timestamp-previousTaskWithInterval的timestamp是否大于previousTaskWithInterval的interval
    */
    if (!task.interval || !previousTaskWithInterval || (task.timestamp - previousTaskWithInterval.timestamp) > previousTaskWithInterval.interval) {
        audioPlay(task);
        isPlaying = true;
    }
    audioElement.onended = () => {
        isPlaying = false;
        previousTask = task;
        if (task.interval) previousTaskWithInterval = task;
        processQueue();
    };
}

export function enqueueNoticeAudioQueue(opt = {}) {
    const timestamp = new Date().getTime();
    notificationQueue.push({ ...opt, timestamp, group: opt.group });
    if (!isPlaying) {
        processQueue();
    }
}