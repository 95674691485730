import Vue from 'vue'
import store from '@/store'
import Router from 'vue-router'
import Layout from '@/pages/layout/Index.vue'
import Dashboard from '@/pages/dashboard/Index.vue'
import setting from '@/config/setting'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getMenuList } from "@/api/menuApi.js";

NProgress.configure({ showSpinner: false, easing: 'ease', speed: 500 })
Vue.use(Router)
// 不需要权限的路由
export const routes = [
    {
        path: '/',
        redirect: '/dashboard/home'
    },
    buildRoute('login', '/login', 'login/Login', { title: '登录', newPage: true }),
    buildRoute('notPermission', '/not-permission', 'exception/notPermission', { title: '无权限', newPage: true }),
    {
        path: '/dashboard',
        component: Layout,
        meta: {
            title: '66印'
        },
        children: [
            {
                path: '/dashboard/home',
                component: Dashboard,
                meta: {
                    title: '首页'
                },
            }
        ]
    },
    // buildRoute('nav', '/web/sys/role', 'admin/sys/role/Index', { title: '无权限'}),
    {
        path: '/exception',
        component: Layout,
        meta: {
            title: '异常页面'
        },
        children: [
            buildRoute('403', '403', 'exception/403', { title: '403' }),
            buildRoute('404', '404', 'exception/404', { title: '404' }),
            buildRoute('500', '500', 'exception/500', { title: '500' }),
        ]
    },
    // buildRoute('notPermission', '*', 'exception/notPermission', { title: '无权限', newPage: true }),
]

const createRouter = () => new Router({
    routes: routes
})
const router = createRouter();
router.beforeEach(async (to, from, next) => {
    let isLogin = false
    let { meta, matched, name, path, params } = to;
    let { title, newPage, keepAlive, isDetail } = meta;
    let { showNprogress } = store.state.setting.setting
    let sys = JSON.parse(localStorage.getItem('sys'))
    if (showNprogress) {
        NProgress.start()
    }
    if (sys) {
        isLogin = sys.user.isLogin;
    }

    params.keepAlive = keepAlive

    router.afterEach(() => {
        if (showNprogress) {
            NProgress.done()
        }
    })

    if (!isLogin && path !== '/login') {
        next('/login')
        return
    } else {
        let { menuList } = store.state.menu;
        // const appList = await getMenuList();
        // let href = location.href.split('/#/')[1].split('/')[0];
        // let hasAppPermission = appList.find(v => { return v.name == href })
        // 打开的页面不存在
        if (menuList && menuList?.children?.length && !matched.length) {
            next('/exception/404')
            return
        }
        // console.log(href, hasAppPermission)
        // if (!hasAppPermission && path !== '/not-permission' && href != 'not-permission' && href != 'dashboard') {
        //     next('not-permission')
        //     return
        // }
        next()
    }

    if (title) {
        document.title = `${title} - ${setting.systemName}`
    }
    // 不是标签页
    if (newPage || isDetail || path == '/exception/404' || path == '/not-permission') {
        return
    }
    let { name: fName, meta: fMeta, path: fPath } = from
    // 路由添加到标签页

    store.dispatch('worktab/worktabRoute', {
        to: {
            name: name || '',
            title: (meta && title) ? getParentRoureTitle(to) + title : '',
            path,
            params
        },
        from: {
            name: fName || '',
            title: (fMeta && fMeta.title) ? fMeta.title : '',
            path: fPath,
            params
        }
    })
    // 设置网页title
    return
})



// 构建静态路由
function buildRoute(name, path, component, meta) {
    return {
        name,
        path,
        component: () => import(`@/pages/${component}`),
        meta
    }
}
// 生成权限路由
export function makeRoute(items) {
    for (let i = 0; i < items.length; i++) {
        if (items[i].nav_type == 3) {
            if (items[i].children && items[i].children.length) {
                items[i].meta.auth_button = items[i].children.map(v => {
                    return {
                        button_type: v.button_type,
                        display: v.display,
                        title: v.title
                    };
                });
                delete items[i].children;
            }
        }
        if (items[i].nav_type !== 4) items[i] = formatRoute(items[i]);

        if (items[i].children && items[i].nav_type !== 4) {
            //递归处理子路由的component
            items[i].children = makeRoute(items[i].children);
        }
    }
    return items;
}
// 转后端返回字符串组件为component
function formatRoute(route) {
    return {
        name: route.name,
        path: route.path,
        component: _import(route.component),
        children: route.children,
        redirect: route.redirect,
        meta: {
            id: route.id,
            title: route.title,
            ...route.meta
        }
    };
}

function getParentRoureTitle(route) {
    if (!route.name) return '';
    let name = route.name;

    let nameArr = name.split('.').slice(0);
    nameArr.pop();
    let parentRoute = route.matched.find(v => v.name == nameArr.join('.'))
    return (parentRoute && parentRoute.meta.title) ? parentRoute.meta.title + ' - ' : '';
}

function _import(componentUrl) {
    // return () => import(`../pages/${componentUrl}.vue`);
    return () => import(/* webpackChunkName: "[request]" */ `../pages/${componentUrl}.vue`);
}
// 重置路由
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

// 获取原型对象上的push函数
const originalPush = Router.prototype.push
// 修改原型对象中的push方法
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router
