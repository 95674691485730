<template>
    <div class="chat-group">
        <div class="logo">
            <img src="@/assets/img/avatar/logo.png" alt="" style="height: 30px;">
        </div>
        <div class="group">
            <div v-for="(item, index) in groupList" :key="index" :class="{'active': item.id == currentTab}" @click="selectGroup(item)">
                <span>{{item.name}}<el-badge :value="item.unReadCount" :max="99" v-if="item.unReadCount !=0"></el-badge></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        groupList: {
            type: Array,
            default: []
        },
        chatlogs: {
            type: Array,
            default: []
        }
    },
    components: {
    },
    data() {
        return {
            currentTab: null,
            loading: false
        };
    },

    methods: {
        selectGroup(item) {
            this.currentTab = item.id;
            this.$emit('groupChange', item)
        },
        calcUnReadCount(chatlogs) {
            for (let i = 0; i < this.groupList.length; i ++) {
                this.groupList[i].unReadCount = 0;
                chatlogs.forEach(v => {
                    if (v.source == this.groupList[i].id) {
                        this.groupList[i].unReadCount += v.unReadCount;
                    }
                })
            }
            this.$forceUpdate()
        }
    },
    watch: {
        chatlogs: {
            handler(val) {
                this.calcUnReadCount(val)
            },
            deep: true,
            immediate: true
        }
    },
};
</script>
<style lang="scss" scoped>
.chat-group {
    width: 9%;
    display: inline-block;
    border-right: 1px solid #e8e8e8;
    height: 100%;
    background: #fff;
    border-radius: 12px 0px 0px 12px;
    user-select: none;
    :deep(.el-badge__content) {
        position: relative;
        top: -6px;
        right: 0px;
        height: 14px;
        line-height: 14px;
        background: red;
        padding: 0 4px;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .logo {
        border-bottom: 1px solid #e8e8e8;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .group {
        height: calc(100% - 40px);
        overflow: auto;
        > div {
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            // border-bottom: 1px solid #e8e8e8;
            cursor: pointer;
            position: relative;
            > span {
                width: 80px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            &.active {
                background: #00d2ff;
                color: #fff;
            }
            :deep(.el-badge) {
                position: absolute;
                right: 10px;
            }
        }
    }
}
</style>
