/*
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-10-09 17:21:49
 * @LastEditors: llg
 * @LastEditTime: 2022-10-12 10:57:32
 */
import store from '@/store'
import router, { resetRouter } from '@/router'
import { makeRoute } from '@/router/index.js'

const route = {
    path: '*',
    component: () => import(`@/pages/exception/notPermission`)
}

export let permissionRoutes = [];

export function buildRoute(menuData) {
    if (!menuData) return;
    // 重置路由
    resetRouter();
    // 用户无权限进入无权限页面
    if (menuData && !menuData.length) {
        router.push({ name: 'notPermission' });
        return;
    };
    let currentApp = store.state.setting.setting.currentApp;
    if (currentApp) {
        let currentMenuData = menuData.find(v => { return v.name == currentApp });
        if (currentMenuData) {
            store.dispatch('menu/setMenuList', currentMenuData)
        } else {
            store.dispatch('menu/setMenuList', menuData[0]);
        }
    } else {
        store.dispatch('menu/setMenuList', menuData[0]);
    }
    // 构建路由
    permissionRoutes = makeRoute(JSON.parse(JSON.stringify(menuData)));
    permissionRoutes.push(route)

    // 插入路由
    permissionRoutes.forEach(route => {
        router.addRoute(route);
    });
    // router.push("/");
}

// 判断menuData中是否包含nav_type == 3，有的话就是有权限，要递归
// export function hasPermission(menuData) {
//     if (!menuData) return;
//     let flag = false;
//     menuData.forEach(item => {
//         if (item.nav_type == 3) {
//             flag = true;
//         } else {
//             if (item.children && item.children.length) {
//                 flag = hasPermission(item.children);
//             }
//         }
//     });
//     return flag;
// }