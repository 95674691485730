import axios from '@/utils/request';

export function getBusinessPageList(params) {
    return axios.post({
        url: '/api/Business/GetBusinessPageList',
        data:params
    }).then((res) => {
        return res
    })
}

export function getBusinessAuditPageList(params) {
    return axios.post({
        url: '/api/Business/GetBusinessAuditPageList',
        data:params
    }).then((res) => {
        return res
    })
}

export function getBusinessDetailById() {
    return axios.post({
        url: '/api/v1/businessSettled/getMyBusinessSettledInfo',
    }).then((res) => {
        return res
    })
}

export function businessRecommendSet(params) {
    return axios.post({
        url: '/api/Business/BusinessRecommendSet',
        data:params
    }).then((res) => {
        return res
    })
}

export function businessStateSet(params) {
    return axios.post({
        url: '/api/Business/BusinessStateSet',
        data:params
    }).then((res) => {
        return res
    })
}

export function businessTopSet(params) {
    return axios.post({
        url: '/api/Business/BusinessTopSet',
        data:params
    }).then((res) => {
        return res
    })
}

export function businessAuditAgree(id) {
    return axios.get({
        url: '/api/Business/BusinessAuditAgree/' + id,
    }).then((res) => {
        return res
    })
}

export function businessAuditRefuse(params) {
    return axios.post({
        url: '/api/Business/BusinessAuditRefuse',
        data:params
    }).then((res) => {
        return res
    })
}

export function businessAuditRefuseBatch(params) {
    return axios.post({
        url: '/api/Business/BusinessAuditRefuseBatch',
        data:params
    }).then((res) => {
        return res
    })
}

export function businessAuditAgreeBatch(params) {
    return axios.post({
        url: '/api/Business/BusinessAuditAgreeBatch',
        data:params
    }).then((res) => {
        return res
    })
}

export function businessInfoSave(params) {
    return axios.post({
        url: '/api/Business/BusinessInfoSave',
        data:params
    }).then((res) => {
        return res
    })
}

// 商家属性
export function getAttributeWithItem() {
    return axios.get({
        url: '/api/BusinessAttribute/GetAttributeWithItem',
    }).then((res) => {
        return res
    })
}

// ---------------------------------------------------------------
export function getBusinessSettledList(params) {
    return axios.post({
        url: '/api/v1/businessSettled/getBusinessSettledList',
        data:params
    }).then((res) => {
        return res
    })
}

export function exportBusinessSettledList(params) {
    return axios.post({
        url: '/api/v1/businessSettled/exportBusinessSettledList',
        data:params
    }).then((res) => {
        return res
    })
}

export function updateBusinessSettledStatus(params) {
    return axios.post({
        url: '/api/v1/businessSettled/updateBusinessSettledStatus',
        data:params
    }).then((res) => {
        return res
    })
}

export function getBusinessSettledInfo(params) {
    return axios.post({
        url: '/api/v1/businessSettled/getBusinessSettledInfo',
        data:params
    }).then((res) => {
        return res
    })
}

export function getBusinessAttributeWithItem(params) {
    return axios.get({
        url: '/api/v1/businessAttribute/getBusinessAttributeWithItem',
        data:params
    }).then((res) => {
        return res
    })
}

export function editBusinessSettled(params) {
    return axios.post({
        url: '/api/v1/businessSettled/editBusinessSettled',
        data:params
    }).then((res) => {
        return res
    })
}

export function getBusinessSettledAuditList(params) {
    return axios.post({
        url: '/api/v1/businessSettledAudit/getBusinessSettledAuditList',
        data:params
    }).then((res) => {
        return res
    })
}

export function getWpsBusinessSettledAuditList(params) {
    return axios.post({
        url: '/api/v1/businessSettledAudit/getWpsBusinessSettledAuditList',
        data:params
    }).then((res) => {
        return res
    })
}

export function getBusinessSettledAuditInfo(params) {
    return axios.post({
        url: '/api/v1/businessSettledAudit/getBusinessSettledAuditInfo',
        data:params
    }).then((res) => {
        return res
    })
}

export function agreeBusinessSettled(params) {
    return axios.post({
        url: '/api/v1/businessSettledAudit/agreeBusinessSettled',
        data:params
    }).then((res) => {
        return res
    })
}

export function refuseBusinessSettled(params) {
    return axios.post({
        url: '/api/v1/businessSettledAudit/refuseBusinessSettled',
        data:params
    }).then((res) => {
        return res
    })
}

export function getBusinessShipAddressList(params) {
    return axios.post({
        url: '/api/v1/businessShipAddress/getBusinessShipAddressList',
        data: params
    }).then((res) => {
        return res
    })
}

export function updateBusinessShipAddress(params) {
    return axios.post({
        url: '/api/v1/businessShipAddress/updateBusinessShipAddress',
        data: params
    }).then((res) => {
        return res
    })
}