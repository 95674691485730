/*
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-03-29 14:13:24
 * @LastEditors: llg
 * @LastEditTime: 2022-03-29 15:07:35
 */

import axios from 'axios';
import store from "../store";
export default function refreshToken() {
    try {
        let { refreshToken } = store.state.user.user.info;
        return axios.get(`/api/Login/RefreshToken?refreshToken=${refreshToken}`);
    } catch (error) {
        console.log(error);
    }
}