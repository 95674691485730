<template>
    <div class="chat-group">
        <div class="logo">
            <img src="@/assets/img/avatar/logo.png" alt="" style="height: 30px;">
        </div>
        <div class="group">
            <div v-for="(item, index) in chatlogs" :key="index" :class="{'active': item.source == currentTab}" @click="selectGroup(item)">
                <span>{{formatSource(item.source)}}<el-badge :value="item.unReadCount" :max="99" v-if="item.unReadCount !=0"></el-badge></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        chatlogs: {
            type: Array,
            default: () => {
                return []
            }
        },
        sourceList: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    components: {
    },
    data() {
        return {
            currentTab: null,
            loading: false,
            _chatlogs: []
        };
    },

    methods: {
        selectGroup(item) {
            this.currentTab = item.source;
            this.$emit('groupChange', item)
        },
        formatSource(val) {
            let source = this.sourceList.find(v => v.id == val)
            return source ? source.name : val    
        },
        calcUnReadCount() {
            this._chatlogs = this.chatlogs.map(v => {
                let count = 0
                v.children.forEach(v => {
                    count += v.unReadCount
                })
                v.unReadCount = count
                return v
            })
        }
    },
};
</script>
<style lang="scss" scoped>
.chat-group {
    width: 9%;
    display: inline-block;
    border-right: 1px solid #e8e8e8;
    height: 100%;
    background: #fff;
    border-radius: 12px 0px 0px 12px;
    user-select: none;
    :deep(.el-badge__content) {
        position: relative;
        top: -6px;
        right: 0px;
        height: 14px;
        line-height: 14px;
        background: red;
        padding: 0 4px;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .logo {
        border-bottom: 1px solid #e8e8e8;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .group {
        height: calc(100% - 40px);
        overflow: auto;
        > div {
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            // border-bottom: 1px solid #e8e8e8;
            cursor: pointer;
            position: relative;
            > span {
                width: 80px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            &.active {
                background: #00d2ff;
                color: #fff;
            }
            :deep(.el-badge) {
                position: absolute;
                right: 10px;
            }
        }
    }
}
</style>
