import { Dialog, Drawer } from "element-ui";

const dialogPatch = {
    extends: Dialog,
    data() {
        return {
            wrapperMouseDown: false // 是否点击遮罩
        }
    },
    mounted() {
        this.$el.addEventListener('mousedown', (e) => {
            this.wrapperMouseDown = e.target.classList.contains('el-dialog__wrapper');
        })
    },
    methods: {
        handleWrapperClick() {
            if (!this.closeOnClickModal || !this.wrapperMouseDown) return;
            this.handleClose();
        },
    },
}

const drawerPatch = {
    extends: Drawer,
    data() {
        return {
            wrapperMouseDown: false // 是否点击遮罩
        }
    },
    mounted() {
        this.$el.addEventListener('mousedown', (e) => {
            this.wrapperMouseDown = e.target.classList.contains('el-drawer__container');
        })
    },
    methods: {
        handleWrapperClick() {
            if (this.wrapperClosable && this.wrapperMouseDown) {
                this.closeDrawer();
            }
        },
    },
}


export default (Vue) => {
    Vue.component(Dialog.name, dialogPatch)
    Vue.component(Drawer.name, drawerPatch)
}
