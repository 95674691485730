/*
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-02-21 12:34:20
 * @LastEditors: llg
 * @LastEditTime: 2022-10-14 16:54:45
 */
import axios from '@/utils/request'

// get请求
export function doGet(url = '', options = {}) {
    url = url.replace('/adminapi', '/liushizhou')
    return function (params = {}) {
        return axios.request({
            url,
            params,
            ...options
        })
    }
}

export function post(url = '', options = {}) {
    url = url.replace('/adminapi', '/liushizhou')
    return async function (data = {}) {
        try {
            const res = await axios.request({
                url,
                data,
                method: 'POST',
                ...options
            })
            return [null, res]
        } catch (err) {
            return [err, null]
        }
    }
}

// post请求
export function doPost(url = '', options = {}) {
    url = url.replace('/adminapi', '/liushizhou')
    return function (data = {}) {
        return axios.request({
            url,
            data,
            method: 'POST',
            ...options
        })
    }
}

// formdata请求
const data = {
    array: [{
        name: 1
    }]
}
export function doUpload(url = '', options = {}) {
    return function (data = {}) {
        let formData = new FormData();
        for (let key in data) {
            if (Array.isArray(data[key])) {
                console.log(data[key])
                formData.append(`${key}`, JSON.stringify(data[key]));
            } else {
                formData.append(key, data[key]);   
            }
        };
      
        
        return axios.request({
            url,
            data:formData,
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            ...options
        })
    }
}

export function doRequest(url = '', options = {}) {
    return function (method, data = {}) {
        if (method == 'DELETE' || method == 'delete') {
            url = url + '/' + data.id;
            return axios.request({
                url,
                method: method,
                ...options
            })
        } else {
            // url = url + '?timestamp=' + Date.now();
            if (method == 'GET' || method == 'get') {
                return axios.request({
                    url,
                    params: data,
                    ...options
                })
            } else {
                return axios.request({
                    url,
                    data,
                    method: method,
                    ...options
                })
            }
        }
    }
}

// 订单
export { getKdOrderDailyLogSum } from "@/api/iflytekApi";
export { getBusinessKdOrderDailyLogSum } from './iflytekApi'
export { getWpsOrderDailyLogSum } from './iflytekApi'
export { getBusinessWpsOrderDailyLogSum } from './iflytekApi'
export { orderDetailRefund } from './iflytekApi'
export { getCustOrderPageList } from "@/api/iflytekApi";
export { getBusinessSettlerPageList } from "@/api/iflytekApi";
export { setBusinessSettler } from "@/api/iflytekApi";
export { getOrderDetailById } from "@/api/iflytekApi";
export { getOrderDetailListByOrderId } from "@/api/iflytekApi";
export { downloadOrder } from "@/api/iflytekApi";
export { wpsDownloadOrderFile } from "@/api/iflytekApi";
export { downloadOrderFileStream } from "@/api/iflytekApi";
export { batchDownloadOrder } from "@/api/iflytekApi";
export { rebuild } from "@/api/iflytekApi";
export { manufactureOrder } from "@/api/iflytekApi";
export { deliveryOrder } from "@/api/iflytekApi";
export { completeOrder } from "@/api/iflytekApi";
export { refundOrder } from "@/api/iflytekApi";
export { getProvinceList } from "@/api/iflytekApi";
export { getCityList } from "@/api/iflytekApi";
export { getAreaList } from "@/api/iflytekApi";
export { doExportList } from "@/api/iflytekApi";
export { doOrderDetailExportList } from "@/api/iflytekApi";
export { updateOrderPage } from "@/api/iflytekApi";
export { rejectOrder } from "@/api/iflytekApi";
export { getOrderDetailInfoById } from "@/api/iflytekApi";
export { updateOrderPrice } from "@/api/iflytekApi";
export { refundMoney } from "@/api/iflytekApi";
export { batchSettle } from "@/api/iflytekApi";
export { addOrderOperationLog } from "@/api/iflytekApi";
export { getOrderOperationLogByOrderId } from "@/api/iflytekApi";
export { doBusinessExportList } from "@/api/iflytekApi";
export { batchManufactureOrder } from "@/api/iflytekApi";
export { batchCompleteOrder } from "@/api/iflytekApi";
export { coverDownload } from "@/api/iflytekApi";
export { getCustOrderDailyLogSum } from "@/api/repotApi";
export { getBCustOrderDailyLogSum } from "@/api/repotApi";
// 新报备
export { getIflyAppointmentList } from "@/api/iflytekApi";
export { refuseIflyAppointment } from "@/api/iflytekApi";
export { delayIflyAppointment } from "@/api/iflytekApi";
export { sendIflyAppointment } from "@/api/iflytekApi";
// 科大日报
export { getKdCustOrderDailyLogPageList } from "@/api/iflytekApi";
export { getKdCustOrderDailyLogSum } from "@/api/iflytekApi";
export { doKdExportList } from "@/api/iflytekApi";
// WPS日报
export { getWpsCustOrderDailyLogPageList } from "@/api/iflytekApi";
export { getWpsCustOrderDailyLogSum } from "@/api/iflytekApi";
export { doWpsExportList } from "@/api/iflytekApi";

// 商家日报
export { getBusinessKdCustOrderDailyLogPageList } from "@/api/iflytekApi";
export { getBusinessKdCustOrderDailyLogSum } from "@/api/iflytekApi";
export { doBusinessKdExportList } from "@/api/iflytekApi";

export { getBusinessWpsCustOrderDailyLogPageList } from "@/api/iflytekApi";
export { getBusinessWpsCustOrderDailyLogSum } from "@/api/iflytekApi";
export { doBusinessWpsExportList } from "@/api/iflytekApi";

// 字典
export { pageInfo } from "@/api/dictionaryApi";
export { formatInfo } from "@/api/dictionaryApi";
export { paperType } from "@/api/dictionaryApi";
export { printType } from "@/api/dictionaryApi";
export { logisticCompany } from "@/api/dictionaryApi";
export { getOrderDownloadStatus } from "@/api/dictionaryApi";
export { getOrderStatus } from "@/api/dictionaryApi";
export { getRoleList } from "@/api/dictionaryApi";
export { getUserSysType } from "@/api/dictionaryApi";
export { businessState } from "@/api/dictionaryApi";
export { businessGrade } from "@/api/dictionaryApi";
export { businessActive } from "@/api/dictionaryApi";
export { businessType } from "@/api/dictionaryApi";
export { businessRecommend } from "@/api/dictionaryApi";
export { businessTopEnum } from "@/api/dictionaryApi";
export { getAppointStatus } from "@/api/dictionaryApi";
export { getPickMethod } from "@/api/dictionaryApi";
export { getDeliverMethod } from "@/api/dictionaryApi";
export { getWpsSettleState } from "@/api/dictionaryApi";
export { getWpsIsTicket } from "@/api/dictionaryApi";
export { auditState } from "@/api/dictionaryApi";
export { getIFlytekStatus } from "@/api/dictionaryApi";
export { getAppointmentSource } from "@/api/dictionaryApi";
export { getAppointmentStatus } from "@/api/dictionaryApi";
export { withdrawType } from "@/api/dictionaryApi";
export { paymentStatus } from "@/api/dictionaryApi";
export { refundType } from "@/api/dictionaryApi";

// 用户管理
export { getCustAccountPageList } from "@/api/userApi";
export { createUser } from "@/api/userApi";
export { deleteUser } from "@/api/userApi";
export { updatePwd } from "@/api/userApi";
export { doUserExportList } from "@/api/userApi";

// 商家
export { getBusinessPageList } from "@/api/branchApi";
export { getBusinessAuditPageList } from "@/api/branchApi";
export { exportBusinessSettledList } from "@/api/branchApi";
export { getBusinessDetailById } from "@/api/branchApi";
export { businessRecommendSet } from "@/api/branchApi";
export { businessStateSet } from "@/api/branchApi";
export { businessTopSet } from "@/api/branchApi";
export { businessAuditAgree } from "@/api/branchApi";
export { businessAuditRefuse } from "@/api/branchApi";
export { businessAuditAgreeBatch } from "@/api/branchApi";
export { businessAuditRefuseBatch } from "@/api/branchApi";
export { businessInfoSave } from "@/api/branchApi";
// 商家属性
export { getAttributeWithItem } from "@/api/branchApi";


// login
export { getVerificationCode } from "@/api/loginApi";
export { forgetPwd } from "@/api/loginApi";
export { getImageVerificationCode } from "@/api/loginApi";

// 物流
export { getOrderLogisticsInfo } from "@/api/logisticsApi";
export { updateLogisticNumber } from "@/api/logisticsApi";

// 纸张
export { getPaperOrderPageList } from "@/api/paperApi";
export { doPaperOrderExportList } from "@/api/paperApi";

// 报备
export { getIflytekAppointmentOrderPageList } from "@/api/iflytekApi";
export { posted } from "@/api/iflytekApi";
export { delayed } from "@/api/iflytekApi";
export { reject } from "@/api/iflytekApi";

// 云盒管理
export { deleteBox } from "@/api/cloudBoxApi";
export { editBox } from "@/api/cloudBoxApi";
export { getBoxInfo } from "@/api/cloudBoxApi";
export { getBoxPrinter } from "@/api/cloudBoxApi";
export { editBoxPrint } from "@/api/cloudBoxApi";
export { editBoxPrintPrice } from "@/api/cloudBoxApi";
export { getBoxOrderList } from "@/api/cloudBoxApi";
export { getBoxMeterReadingList } from "@/api/cloudBoxApi";
export { getBoxMeterReadingListGroupByMonth } from "@/api/cloudBoxApi";
export { getFeedbackList } from "@/api/cloudBoxApi";
export { getNoticeMessageList } from "@/api/cloudBoxApi";
export { addNoticeMessage } from "@/api/cloudBoxApi";
export { addBoxPrinterModel } from "@/api/cloudBoxApi";
export { getBoxPrinterModelList } from "@/api/cloudBoxApi";
export { deleteBoxPrinterModel } from "@/api/cloudBoxApi";
export { getBoxErrorLogList } from "@/api/cloudBoxApi";
export { editBoxErrorLog } from "@/api/cloudBoxApi";
export { getErrorInfo } from "@/api/cloudBoxApi";
export { getOrderDetailList } from "@/api/cloudBoxApi";
export { getOrderInfo } from "@/api/cloudBoxApi";
export { printOrderDetail } from "@/api/cloudBoxApi";
export { getOperateLogList } from "@/api/cloudBoxApi";
export { exportOrderList } from "@/api/cloudBoxApi";
export { exportBoxList } from "@/api/cloudBoxApi";
export { exportBoxErrorLogList } from "@/api/cloudBoxApi";
export { exportBoxMeterReadingList } from "@/api/cloudBoxApi";
export { exportBoxMeterReadingListGroupByMonth } from "@/api/cloudBoxApi";
export { updateBoxStatus } from "@/api/cloudBoxApi";
export { getBoxMeterReadingInfoGroupByMonth } from "@/api/cloudBoxApi";
export { exportOrderDetailList } from "@/api/cloudBoxApi";


export { getPaperSizeList } from "@/api/cloudBoxApi";
export { getPhotoSizeList } from "@/api/cloudBoxApi";
export { getCertificateSizeList } from "@/api/cloudBoxApi";
export { getImageSizeList } from "@/api/cloudBoxApi";
export { getNoticeTargetList } from "@/api/cloudBoxApi";
export { getPrinterStatusList } from "@/api/cloudBoxApi";
export { getErrorStatusList } from "@/api/cloudBoxApi";
export { getOrderStatusList } from "@/api/cloudBoxApi";
export { getLineStatusList } from "@/api/cloudBoxApi";
export { getWarmingStatusList } from "@/api/cloudBoxApi";

// 新商家
export { getBusinessSettledList } from "@/api/branchApi";
export { updateBusinessSettledStatus } from "@/api/branchApi";
export { getBusinessSettledInfo } from "@/api/branchApi";
export { getBusinessAttributeWithItem } from "@/api/branchApi";
export { editBusinessSettled } from "@/api/branchApi";

// 商家审核
export { getBusinessSettledAuditList } from "@/api/branchApi";
export { getBusinessSettledAuditInfo } from "@/api/branchApi";
export { getWpsBusinessSettledAuditList } from "@/api/branchApi";
export { agreeBusinessSettled } from "@/api/branchApi";
export { refuseBusinessSettled } from "@/api/branchApi";

// common
export { uploadPrinSet } from "@/api/commonApi";

// 商家信息管理
export { getBusinessShipAddressList } from "@/api/branchApi"
// 修改快递揽收地址
export { updateBusinessShipAddress } from '@/api/branchApi'