<template>
    <div>
        <transition-group name="fade-list" mode="out-in">
            <div class="cart-controller" v-show="!isMinimize" key="box3">
                <div style="position: absolute;right: 10px;top: 7px;font-size: 24px;cursor: pointer;">
                    <el-select v-model="statusId" placeholder="请选择状态" size="mini" @change="changeChatUser" style="width: 120px;vertical-align: text-bottom;margin-right: 6px;">
                        <el-option v-for="item in ststusOptions" :key="item.id" :label="item.serviceStatusName" :value="item.id">
                        </el-option>
                    </el-select>
                    <i class="el-icon-remove-outline" @click="messageClick"></i>
                </div>
                <div class="box">
                    <chat-group :groupList="groupList" @groupChange="groupChange" :chatlogs="chatlogs" />
                    <chat-user :chatlogs="chatlogs" :currentGroup="currentGroup" @getCurrentUser="getCurrentUser" ref="chatUser" />
                    <div class="chat">
                        <transition name="fade" mode="out-in">
                            <div v-if="currentUser.senderId" key="box1" style="height: 100%;" v-loading="loading">
                                <div class="top">
                                    <chat-header :currentUser="currentUser" @closeConnection="closeConnection" :sourceList="sourceList" :canchat="canchat" />
                                </div>
                                <div class="bottom">
                                    <div class="left">
                                        <chat-message ref="chatMessage" :messageList="messageList" :currentUser="currentUser" :connection="connection" :canchat="canchat" />
                                    </div>
                                    <div class="right">
                                        <chat-opera :currentUser="currentUser" @sendCommonreply="sendCommonreply" />
                                    </div>
                                </div>
                            </div>
                            <div class="bottom" v-else style="justify-content: center;" key="box2">
                                <el-empty description="暂无消息" style="margin-top: -100px;"></el-empty>
                            </div>
                        </transition>
                    </div>
                </div>
                <!-- 转接 -->
                <el-dialog :visible.sync="visible" width="250px" :title="null" center :show-close="false" custom-class="status-modal" :top="'45vh'">
                    <div style="text-align: center;">
                        <div>是否转移当前聊天记录？</div>
                        <div style="margin-top:24px;display: flex;justify-content: space-evenly;">
                            <el-button @click="transfer(false)" size="mini"> 不转移</el-button>
                            <el-button type="primary" @click="transfer(true)" size="mini">转 移</el-button>
                        </div>
                    </div>
                </el-dialog>
            </div>
            <div v-show="isMinimize" class="minimize" @click="messageClick" key="box4" :class="{'flashing': newMessage}" @mousedown="mousedown" :style="{top: messageTop}">
                客服(移动端)
            </div>
        </transition-group>
        <audio :src="audioSrc" id="imAudio" @ended="overAudio"></audio>
    </div>
</template>

<script>
import { ChatGroup, ChatUser, ChatOpera, ChatMessage, ChatHeader } from './components';
import * as signalR from "@aspnet/signalr";
import { printsourcelist, chatlog, chatlogpage, statuslabel, changeChatUser, transfer, canchat } from '@/api/chatApi';
import { Notification } from 'element-ui';
import axios from 'axios';
export default {
    components: {
        ChatGroup,
        ChatUser,
        ChatOpera,
        ChatMessage,
        ChatHeader,
    },
    data() {
        return {
            currentChat: {},
            text: null,
            connection: null,
            chatUser: [],
            messageList: [],
            sourceList: [],
            groupList: [],
            chatlogs: [],
            currentGroup: {},
            currentUser: {},
            myInfo: {},
            ststusOptions: [],
            statusId: '',
            visible: false,
            currentStatus: null,
            transferMessage: [],
            canchat: true,
            isMinimize: true,
            newMessage: false,
            loading: false,
            timer: null,
            audioSrc: require('@/assets/audio/imMessage.mp3'),
            closeTimer: null,
            canClick: true,
            messageTop: '80vh',
        };
    },

    methods: {
        initSignalR() {
            let { token } = this.$store.state.user.user.info;
            this.connection = new signalR.HubConnectionBuilder().withUrl(`/chatHub/chatHub`, {
                accessTokenFactory: () => token
            }).configureLogging(signalR.LogLevel.Error).build();
            this.connection.onclose(msg => {
                if (process.env.NODE_ENV == 'production') {
                    axios.post('/robot/send?access_token=5d44965bd6061415f6eb5b5a0210c37a0a217b4ba7902df294603eac9c4bb51d', {
                        msgtype: "text",
                        text: {
                            "content": `后台客服系统error：连接断开，客服信息:${this.myInfo.userId ? JSON.stringify(this.myInfo) : '非客服人员'}，错误信息：${msg}`
                        },
                    });
                }
            })
            // 保证这个接收是唯一的
            this.connection.off("ReceiveMessage");
            this.connection.off("CurrrentUserInfo");
            // 监听当前登陆用户
            this.connection.on("CurrrentUserInfo", myInfo => {
                // 当前登陆用户
                this.myInfo = myInfo;
                this.statusId = this.myInfo.serviceStatusId;
                this.$store.commit('chat/setOnlineUser', myInfo);
            })
            // 监听消息
            this.connection.on("ReceiveMessage", res => {
                const message = res;
                const chatMessageType = message.chatMessageType;
                const h = this.$createElement;
                // 转接发起
                if (chatMessageType == 8) {
                    message.notification = Notification({
                        message: h('div', { style: 'text-align: center', }, [
                            h('i', { class: 'el-icon-loading', style: 'font-size: 24px; margin-bottom: 12px' }),
                            h('div', {}, '转接中，等待对方确认'),
                        ]),
                        position: 'bottom-right',
                        duration: 0,
                        showClose: false
                    });
                    this.transferMessage.push(message);
                    // 转接接收
                } else if (chatMessageType == 9) {
                    message.notification = Notification({
                        message: h('div', { style: 'text-align: center', }, [
                            h('i', { class: 'el-icon-loading', style: 'font-size: 24px; margin-bottom: 12px' }),
                            h('div', {}, message.sender + '向你发起转接'),
                            h('div', { style: 'text-align: center;margin-top:6px' }, [
                                h('el-button', {
                                    class: 'el-button--danger', on: {
                                        'click': () => {
                                            this.transferHand(message, false);
                                        }
                                    }
                                }, '拒绝'),
                                h('el-button', {
                                    class: 'el-button--primary', on: {
                                        'click': () => {
                                            this.transferHand(message, true);
                                        }
                                    }
                                }, '同意')
                            ])
                        ]),
                        position: 'bottom-right',
                        duration: 0,
                        showClose: false
                    });
                    // 接受转接
                } else if (chatMessageType == 10 || chatMessageType == 11) {
                    let iconType, text;
                    for (let i = 0; i < this.transferMessage.length; i++) {
                        if (this.transferMessage[i].chatLogContent == message.chatLogContent) {
                            this.transferMessage[i].notification.close();
                            this.transferMessage.splice(i, 1);
                        }
                    };
                    if (message.chatMessageType == 10) {
                        iconType = 'el-icon-success';
                        text = '转接成功';
                    } else if (message.chatMessageType == 11) {
                        iconType = 'el-icon-error';
                        text = '对方拒绝转接，请重新选择';

                        // console.log(message);
                        // let item = this.chatlogs.find(v => { return v.senderId == message.senderId});
                        // console.log(item);
                        // item.canChat = true;
                    }
                    Notification({
                        message: h('div', { style: 'text-align: center', }, [
                            h('i', { class: iconType, style: 'font-size: 24px; margin-bottom: 12px' }),
                            h('div', {}, text),
                        ]),
                        position: 'bottom-right',
                        duration: 5000,
                        showClose: false
                    });
                } else if (chatMessageType == 1 || chatMessageType == 13) {
                    this.newMessage = true;
                    if (this.myInfo.userId == message.senderId) {
                        if (this.$refs.chatMessage) {
                            this.$refs.chatMessage.text = '';
                            this.$message.success('消息发送成功');
                        }
                    } else {
                        this.audioPlay();
                    }
                    let group = this.groupList.find(v => { return v.id == message.source });
                    if (group) {
                        let item = this.chatlogs.find(v => { return (v.senderId == message.senderId) && (v.source == message.source) });
                        if (item) {
                            item.unReadCount = message.unReadCount;
                            item.chatLogSendTime = message.chatLogSendTime;
                            item.chatLogContent = message.chatLogContent
                        } else {
                            if (this.myInfo.userId != message.senderId) {
                                this.chatlogs.unshift(message);
                            }
                        }
                    } else {
                        if (this.myInfo.userId != message.senderId) {
                            this.chatlogs.unshift(message);
                            this.groupList = this.buildGroup(this.sourceList, this.chatlogs);
                        }
                    };
                    this.chatlogs = this.chatlogs.sort((a, b) => { return new Date(b.chatLogSendTime).getTime() - new Date(a.chatLogSendTime).getTime() })
                    if ((this.currentUser.senderId == message.receiverId || this.currentUser.senderId == message.senderId) && this.currentUser.source == message.source) {
                        this.messageList.push(message)
                    }
                };
                if (chatMessageType == 12) {
                    if (this.currentUser && this.currentUser.senderId) {
                        this.getUserCanchat(this.currentUser.senderId)
                    }
                }
                if (chatMessageType == 4 || chatMessageType == 5) {
                    this.currentUser = {};
                    this.$refs.chatUser.currentTab = null;
                }
                if (chatMessageType == 99) {
                    clearInterval(this.closeTimer)
                    this.heartbeat()
                }
            })
            this.connection.start();
        },
        heartbeat() {
            let num = 0;
            this.closeTimer = setInterval(() => {
                num++;
                if (num >= 20) {
                    this.$message.error({
                        showClose: true,
                        message: '客服系统疑似连接中断，请刷新页面恢复连接！',
                        type: 'error',
                        duration: 0
                    });
                    clearInterval(this.closeTimer);
                }
            }, 1000)
        },

        // 获取分组
        async getPrintsourcelist() {
            const res = await printsourcelist('get');
            this.sourceList = res.data;
        },
        // 获取最近聊天列表
        async getChatlog() {
            const res = await chatlog('get');
            this.chatlogs = res.data;
            this.groupList = this.buildGroup(this.sourceList, this.chatlogs);
        },
        buildGroup(sourceList, chatlogs) {
            let groupList = [];
            sourceList.forEach(v => {
                chatlogs.forEach(_v => {
                    if (v.id == _v.source) {
                        if (!groupList.some(v => v.id == _v.source)) {
                            groupList.push({ ...v, })
                        }
                    }
                })
            });
            return groupList;
        },
        groupChange(e) {
            this.currentGroup = e;
        },
        async getCurrentUser(e) {
            this.loading = true;
            const res = await chatlogpage('post', { receiverId: e.senderId, source: e.source });
            this.currentUser = e;
            this.getUserCanchat(this.currentUser.senderId)
            this.messageList = res.data.reverse();
            setTimeout(() => {
                this.loading = false;
            }, 300)
        },
        // 结束会话
        closeConnection() {
            this.sendMessage(4, '结束服务');
        },
        sendCommonreply(e) {
            this.$refs.chatMessage.text = e.content;
        },
        sendMessage(type, message) {
            this.connection.invoke("InvokeService", type, this.currentUser.source, String(this.myInfo.userId), String(this.currentUser.senderId), String(this.myInfo.name), String(this.currentUser.sender), message);
        },
        // 获取状态
        async getStatuslabel() {
            const res = await statuslabel();
            if (res.code == 0) {
                this.ststusOptions = res.data;
            }
        },
        // 切换状态
        async changeChatUser(status) {
            const res = await changeChatUser('put', {
                serviceStatusId: status,
                chatUserName: this.myInfo.userId,
                chatUserImg: '',
            });
            if (res.code == 0) {
                this.getChatlog();
                this.statusId = status;
            }
        },
        async transferHand(message, status) {
            const res = await transfer('put', {
                id: message.chatLogContent,
                transferStatus: status
            });
            if (res.code == 0) {
                message.notification.close();
            }
        },
        // 
        getCurrentStatus(e) {
            this.statusId = e;
        },
        // getUserCanchat
        async getUserCanchat(userId) {
            const res = await canchat('get', {
                chatUserId: userId
            });
            this.canchat = res.data;
        },
        minimize() {
            this.isMinimize = !this.isMinimize;
            this.newMessage = false;
        },
        audioPlay() {
            if (this.audio != null) {
                this.audio.pause();
                this.audio = null;
            }
            this.audio = document.getElementById('imAudio');
            const promise = this.audio.play();
            if (promise !== undefined) {
                promise.then(res => {

                }).catch(error => {
                    this.$confirm('是否授权本次访问自动播放音频?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        if (this.audio) this.audio.play();
                    }).catch(() => {

                    });
                })
            }
        },
        overAudio() {
            this.stopAudio();
        },
        stopAudio() {
            if (this.audio) {
                this.audio.pause();
                this.audio = null;
            }
        },
        mousedown(e) {
            setTimeout(() => {
                this.canClick = true;
                //去除默认样式 - 避免拖动元素出现禁止图标
                e.preventDefault && e.preventDefault();
                //获取目标元素
                let odiv = e.target;
                odiv.style.zIndex = 999999;

                //算出鼠标相对元素的位置
                let disY = e.clientY - odiv.offsetTop;
                let clientHeight = document.documentElement.clientHeight || document.body.clientHeight
                //监听鼠标移动事件
                document.onmousemove = (e) => {
                    //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                    let top = e.clientY - disY;
                    //重新赋值

                    if (top >= clientHeight - 30) {
                        top = clientHeight - 30;
                    } else if (top <= 110) {
                        top = 110
                    }
                    this.messageTop = top + 'px';
                    this.canClick = false;
                };

                //监听鼠标松开
                document.onmouseup = (e) => {
                    document.onmousemove = null;
                    document.onmouseup = null;
                    odiv.style.zIndex = 9999;
                    setTimeout(() => {
                        this.canClick = true;
                    })

                };
            }, 20)
        },
        messageClick() {
            if (!this.canClick) return;
            this.isMinimize = !this.isMinimize;
            this.newMessage = false;
            this.$emit('minimize', 'chat_v1');
        }
    },
    async created() {
        await this.getStatuslabel();
        await this.getPrintsourcelist();
        await this.getChatlog();
        this.initSignalR();
    }
};
</script>
<style lang="scss" scoped>
.minimize {
    position: fixed;
    right: 0px;
    top: 80vh;
    border: 1px solid transparent;
    padding: 6px 12px;
    border-radius: 16px 0px 0px 16px;
    background: rgb(25, 26, 35);
    color: #fff;
    cursor: pointer;
    z-index: 99999;
    user-select: none;
}
.cart-controller {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 50px);
    background: #eee;
    position: fixed;
    width: 100%;
    top: 15px;
    z-index: 3000;
    width: 95%;
    left: 2.5%;
    border-radius: 8px;
    padding-top: 24px;
    .fade-enter-active,
    .fade-leave-active {
        transition: all 0.2s;
    }
    .fade-enter {
        opacity: 0;
        transform: translateX(50px);
    }
    .fade-leave-to {
        opacity: 0;
        transform: translateX(-50px);
    }
    .fade-list-enter-active,
    .fade-list-enter-to {
        transition: all 100.2s;
    }
    .fade-list-enter-active {
        opacity: 0;
        transform: translateY(100%);
    }
    .fade-list-enter-to {
        opacity: 1;
        transform: translateY(0);
    }
    // .fade-enter-active,
    // .fade-leave-active {
    //     transition: opacity 0.3s;
    // }
    // .fade-enter,
    // .fade-leave-to {
    //     opacity: 0;
    // }
    .box {
        width: 100%;
        height: calc(100% - 32px);
        margin: 40px;
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid #ddd;
        box-shadow: 0px 0px 7px 3px rgba(62, 62, 62, 0.05);
        display: flex;
        position: relative;
        overflow: hidden;
        .chat {
            width: 69%;
            display: inline-block;
            .top {
                height: 50px;
                border-bottom: 1px solid #e8e8e8;
                padding: 0 16px;
            }
            .bottom {
                height: calc(100% - 51px);
                display: flex;
                > .left {
                    width: 75%;
                    height: 100%;
                    border: 1px solid #e8e8e8;
                }
                > .right {
                    width: 25%;
                    height: 100%;
                }
            }
        }
    }
    :deep(.status-modal) {
        .el-dialog__header {
            display: none;
        }
        .el-dialog__body {
            padding: 24px;
        }
    }
}

.flashing {
    /*动画元素名-动画整个过程的时间*/
    animation: hzfirst 1s;
    /*动画次数*/
    animation-iteration-count: infinite;
}

@keyframes hzfirst {
    from {
        background-color: #ff7300;
    }
    to {
        background-color: #000;
    }
}
</style>
