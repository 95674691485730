<!--
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-10-20 14:37:36
 * @LastEditors: llg
 * @LastEditTime: 2022-10-25 14:48:10
-->
<template>
    <div class="order">
        <el-dialog width="550px" :visible.sync="visible" title="智能助手聊天记录" :modal-append-to-body='false' append-to-body :custom-class="'chat-log-robot'">
            <div class="chat" ref="scrollDiv">
                <div class="message-content">
                    <div class="message" :class="[item.senderId == 'robot' ? 'left' : 'right']" v-for="(item, index) in data" :key="index">
                        <div class="chat-message">
                            <div>{{item.sender}}</div>
                            <div v-if="item.senderId !== 'robot'">
                                <div v-if="item.chatMessageType == 13 || item.chatLogType == 2" class="image">
                                    <el-image style="width: 100px; height: 100px" :src="'https://' + item.chatLogContent" :preview-src-list="['https://' + item.chatLogContent]">
                                    </el-image>
                                </div>
                                <div v-else>{{item.chatLogContent}}</div>
                                <img :src="item.img || 'https://user.66in.net/static/img/avatar.a38a0a72.png'" alt="" class=" kefuUrl">
                            </div>
                            <div v-else>
                                <img :src="item.img || 'https://user.66in.net/static/img/avatar.a38a0a72.png'" alt="" class=" kefuUrl">
                                <div v-if="item.chatMessageType == 13 || item.chatLogType == 2" class="image">
                                    <el-image style="width: 100px; height: 100px" :src="'https://' + item.chatLogContent" :preview-src-list="['https://' + item.chatLogContent]">
                                    </el-image>
                                </div>
                                <div v-else>{{item.chatLogContent}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { userrobotchatlog } from '@/api/chatApi';
export default {
    data() {
        return {
            visible: false,
            loading: false,
            data: []
        };
    },
    methods: {
        openModal(params) {
            this.visible = true;
            this.getUserRobotChatlog(params);
        },
        async getUserRobotChatlog(params) {
            this.loading = true;
            const res = await userrobotchatlog('post', params)
            if (res.code == 0) {
                this.data = res.data;
                this.loading = false;
                // this.$nextTick(() => {
                //     let scrollElem = this.$refs.scrollDiv;
                //     if (scrollElem) scrollElem.scrollTo({ top: scrollElem.scrollHeight });
                // });
            }
        },
        parseTime(date) {
            return new Date(date).toLocaleString()
        }
    },
    created() {
    },
};
</script>
<style lang="scss">
.chat-log-robot {
    .el-dialog__body {
        height: 600px;
        overflow: auto;
        background: #f1f1f1;
        padding: 6px 12px;
    }
}
</style>

<style lang="scss" scoped>
.chat {
    padding-bottom: 6px;
    padding: 0 11px;
    .message-content {
        > .message {
            margin: 19px 0;
            .kefuUrl {
                width: 30px;
                height: 30px;
            }
        }
        .left {
            text-align: left;
            .chat-message {
                > div:nth-child(1) {
                    margin-left: 35px;
                    color: #c8c8c8;
                    margin-bottom: 2px;
                }
                > div:nth-child(2) {
                    display: flex;
                    align-items: center;
                    > span:nth-child(1) {
                        width: 16px;
                        height: 16px;
                        margin-right: 3px;
                        line-height: 16px;
                        vertical-align: middle;
                        flex-shrink: 0;
                    }
                    > div:nth-child(2) {
                        border-radius: 15px;
                        max-width: 190px;
                        line-height: 1.5;
                        margin-left: 7px;
                        word-break: break-all;
                    }
                    > div:nth-child(2):not(.image) {
                        background: #ffffff;
                        padding: 6px 7px;
                    }
                }
            }
        }
        .right {
            text-align: right;
            .chat-message {
                > div:nth-child(1) {
                    margin-right: 35px;
                    color: #c8c8c8;
                    margin-bottom: 2px;
                }
                > div:nth-child(2) {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    > span:nth-child(2) {
                        width: 16px;
                        height: 16px;
                        line-height: 16px;
                        margin-left: 3px;
                        vertical-align: middle;
                    }
                    > div:nth-child(1) {
                        border-radius: 15px;
                        max-width: 190px;
                        line-height: 1.5;
                        margin-right: 7px;
                        text-align: left;
                        color: #fff;
                        word-break: break-all;
                    }
                    > div:nth-child(1):not(.image) {
                        background: #0096ff;
                        padding: 6px 7px;
                    }
                }
            }
        }
    }
}
</style>