<template>
    <div class="worktab">
        <vue-scroll :ops="ops" ref="scroll">
            <ul class="tabs" ref="tabs">
                <li v-for="(i, index) in worktabs" :key="i.path" :ref="i.path" :class="{'activ-tab' : i.path === activeTab}" @click="clickWorktab(i.path)">
                    {{i.title}}
                    <i class="el-icon-close" @click.stop="closeWorktab('current', i.path)" v-if="index !== 0"></i>
                </li>
                <!-- <li v-for="(i, index) in worktabs" :key="i.path" :class="{'activ-tab' : i.path === activeTab}" @click="clickWorktab(i.path)">
                    {{i.parentTitle}}
                    <i class="el-icon-close" @click.stop="closeWorktab('current', i.path)" v-if="index !== 0"></i>
                </li> -->
                <!-- <el-dropdown :hide-on-click="false" v-for="(i, index) in worktabs" :key="index">
                    <span class="el-dropdown-link" :class="{'activ-tab' : i.path === activeTab}">
                         {{i.parentTitle}}<i class="el-icon-arrow-down el-icon--right"></i><i class="el-icon-close" @click.stop="closeWorktab('current', i.path)" v-if="index !== 0"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item, _index) in i.children" :key="_index" @click.native="clickWorktab(item.path)">{{ item.title }} <i class="el-icon-close" @click.stop="closeWorktab('current', item.path)"></i></el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown> -->
            </ul>
        </vue-scroll>

        <div class="right">
            <el-dropdown @command="closeWorktab">
                <div class="btn el-icon-arrow-down" />
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item icon="el-icon-arrow-left" command="left">
                        <span>关闭左侧</span>
                    </el-dropdown-item>
                    <el-dropdown-item icon="el-icon-arrow-right" command="right">
                        <span>关闭右侧</span>
                    </el-dropdown-item>
                    <el-dropdown-item icon="el-icon-close" command="other">
                        <span>关闭其他</span>
                    </el-dropdown-item>
                    <el-dropdown-item icon="el-icon-error" command="all">
                        <span>关闭全部</span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { scrollBarOpstion } from "@/config/scrollbar.js";

export default {
    name: "Worktab",
    inject: ["reload"],
    computed: {
        ...mapState({
            worktab: (state) => state.worktab.worktab,
            menu: (state) => state.menu,
        }),
        worktabs() {
            // return this.formatTab(this.worktab.opened);
            return this.worktab.opened;
        },
    },
    watch: {
        "worktab.current"(tab) {
            this.activeTab = tab.path;
            let el = this.$refs[tab.path];
            let elWidth = 0;
            let offsetLeft = 0;

            if (el && el.length > 0) {
                elWidth = el[0].clientWidth;
                offsetLeft = el[0].offsetLeft;
            }

            // // 进入控制台刷新页面
            // if (tab.path === '/dashboard/home') {
            //     this.reload();
            // }

            this.worktabAutoPosition(offsetLeft, elWidth);
        },
        "menu.menuList": {
            handler() {
                this.getCurrentUserAllowRouters();
            },
            immediate: true,
        },
    },
    data() {
        return {
            activeTab: "", // 选中的 tab
            ops: scrollBarOpstion,
            allowPath: [], // 存储当前用户可访问的路由
        };
    },
    created() {
        this.initActiveWorktab();
    },
    methods: {
        // 获取当前用户可访问的路由
        getCurrentUserAllowRouters() {
            let { menuList } = this.menu;
            let { routes } = this.$router.options;
        },
        // 重载后选项卡默认选中项
        initActiveWorktab() {
            this.$nextTick(() => {
                const { isDetail } = this.$route.meta;
                const { path } = this.$route;
                // 截取掉最后一个/后面的内容
                if (isDetail) this.activeTab = path.substring(0, path.lastIndexOf("/"));
                else this.activeTab = this.$route.path;
            });
        },
        // 点击选项卡
        clickWorktab(path) {
            let currentPath = this.$route.path;
            let allow = this.allowPath.indexOf(path);

            if (currentPath !== path) {
                // if(allow !== -1) {

                // }else { // 无权限
                //   let page403 = '/exception/403'
                //   if(currentPath !== page403) {
                //     this.$router.push(page403)
                //   }
                // }
                this.$router.push(path);
            }
        },
        /**
         * 根据权限匹配路由并返回
         * @param {array} permission 后台返回的权限列表（菜单列表）
         * @param {array} allowRouters 需要权限的路由表
         */
        routerMatch(permission, allowRouters) {
            return new Promise((resolve) => {
                const paths = [];
                function createRouter(permission) {
                    permission.forEach((item) => {
                        let { path } = item;
                        let pathArr = path && path.split("/");

                        if (pathArr) {
                            path = pathArr[pathArr.length - 1];
                        }

                        if (item.children && item.children.length) {
                            createRouter(item.children);
                        }

                        if (allowRouters.length) {
                            allowRouters.find((s) => {
                                if (s.children) {
                                    s.children.find((y) => {
                                        if (y.path === path) {
                                            paths.push(s.path + "/" + y.path);
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
                createRouter(permission);
                resolve(paths);
            });
        },
        /**
         * 关闭选项卡
         * type {current: 当前, left: 左侧, right: 右侧, other: 其它, all: 所有}
         */
        closeWorktab(type, tabPath) {
            let { path } = this.$route;
            let router = this.$router;
            let action = "";

            switch (type) {
                case "current":
                    action = "worktabRemove";
                    if (tabPath) {
                        path = tabPath;
                    }
                    break;
                case "left":
                    action = "worktabRemoveLeft";
                    break;
                case "right":
                    action = "worktabRemoveRight";
                    break;
                case "other":
                    action = "worktabRemoveOther";
                    break;
                case "all":
                    action = "worktabRemoveAll";
                    break;
            }

            this.$store.dispatch("worktab/" + action, { path, router });
        },
        /**
         * 工作台选项卡自动定位
         * 滚动逻辑：标签超出可视区域自动定位
         */
        worktabAutoPosition(offsetLeft, labelWidth) {
            let x = offsetLeft;
            let labelRight = 6;
            const scroll = this.$refs["scroll"];
            const panel = scroll.scrollPanelElm;
            const panelWidth = panel.clientWidth;
            const scrollLeft = panel.scrollLeft;

            offsetLeft += labelWidth + labelRight;

            if (offsetLeft > panelWidth + scrollLeft) {
                x = offsetLeft - panelWidth;
            }
            if (offsetLeft - labelWidth - labelRight < scrollLeft) {
                x = offsetLeft - labelWidth - labelRight;
            }
            scroll.scrollTo({ x });
        },
        formatTab(array) {
            const categorized = array.reduce((acc, curr) => {
                const key = `${curr.parentRouteName}-${curr.parentTitle}`;
                if (!acc[key]) {
                    acc[key] = { parentRouteName: curr.parentRouteName, parentTitle: curr.parentTitle, children: [] };
                }
                acc[key].children.push(curr);
                return acc;
            }, {});
            const output = Object.values(categorized);
            return output
        }
    },
};
</script>
<style lang="scss">
.worktab {
    .__panel::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
        color: transparent;
    }
}
</style>

<style lang="scss" scoped>
.worktab {
    width: 100%;
    display: flex;
    justify-content: space-between;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    padding: 8px 15px;
    box-sizing: border-box;
    background: $default-background;
    .tabs {
        width: 100px;
        display: flex;
        white-space: nowrap;
        background: transparent !important;

        li {
            height: 30px;
            line-height: 30px;
            text-align: center;
            color: #808695;
            font-size: 13px;
            padding: 0 12px;
            cursor: pointer;
            border-top: 2px solid transparent;
            transition: color 0.1s;
            background: #fff;
            border-radius: 3px;
            margin-right: 6px;

            &:hover {
                color: #515a6e;
                transition: color 0.2s;
            }

            i {
                color: #808695;
                padding: 2px;
                margin-left: 5px;
                border-radius: 50%;
                transition: all 0.2s;

                &:hover {
                    background: #eee;
                }
            }
        }

        .activ-tab {
            color: $theme-color !important;
        }
    }

    .right {
        .btn {
            font-size: 16px;
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            cursor: pointer;
            position: relative;
            top: 0;
            border-left: 1px solid #f2f2f2;
            background: #fff;
            border-radius: 3px;
            box-shadow: 0 0 5px #eee;

            &:hover ul {
                display: inline;
            }
        }
    }
}

@media only screen and (max-width: $device-ipad) {
    .worktab {
        padding: 6px 10px;
    }
}
</style>