<template>
    <div class="chat-message">
        <div style="height: 100%;">
            <div class="chat-content" ref="scrollDiv">
                <div class="message" :class="[userInfo.userId == item.senderId? 'right' : 'left']" v-for="(item, index) in messageList" :key="index">
                    <div v-if="userInfo.userId == item.senderId">
                        <span>{{ item.chatLogSendTime }}</span>
                        
                    </div>
                    <div v-else>
                        <span class="name">{{item.sender}}</span>
                        <span>{{ item.chatLogSendTime }}</span>
                    </div>
                    <div v-if="userInfo.userId == item.senderId">
                        <div v-if="item.chatMessageType == 13 || item.chatLogType == 2" class="image">
                            <el-image style="width: 100px; height: 100px" :src="'https://' + item.chatLogContent" :preview-src-list="['https://' + item.chatLogContent]">
                            </el-image>
                        </div>
                        <div v-else>{{item.chatLogContent}}</div>
                        <el-avatar icon="el-icon-user-solid"></el-avatar>
                    </div>
                    <div v-else>
                        <el-avatar icon="el-icon-user-solid"></el-avatar>
                        <div v-if="item.chatMessageType == 13 || item.chatLogType == 2" class="image">
                            <el-image style="width: 100px; height: 100px" :src="'https://' + item.chatLogContent" :preview-src-list="['https://' + item.chatLogContent]">
                            </el-image>
                        </div>
                        <div v-else>{{item.chatLogContent}}</div>
                    </div>
                </div>
            </div>
            <div class="opera-content">
                <div style="position: relative;width: 22px;height: 22px;">
                    <i class="el-icon-picture-outline" style="position: absolute;cursor: not-allowed;color:#ddd;" v-if="!canchat"></i>
                    <el-upload class="upload-demo" action="/chatapi/Common/UploadFile" :show-file-list="false" :on-success="uploadSuccess" :on-error="uploadError" v-else>
                        <i class="el-icon-picture-outline"></i>
                    </el-upload>
                </div>
                <div>
                    <div @click="getOrderList">
                        获取单号
                    </div>
                    <div @click="getUserRobotChatlog">
                        获取记录
                    </div>
                </div>
            </div>
            <div class="typing-area">
                <el-input type="textarea" show-word-limit :maxlength="200" placeholder="请输入内容" v-model="text" resize="none" @keydown.enter.native="sendMessage(1, text)">
                </el-input>
                <div>
                    <el-button type="primary" size="mini" @click="sendMessage(1, text)" :disabled="!text.trim() || !canchat">发送</el-button>
                </div>
            </div>
        </div>
        <chat-order ref="chatOrder" />
        <chat-log-robot ref="chatLogRobot" />
    </div>
</template>

<script>
import ChatOrder from './ChatOrder.vue';
import ChatLogRobot from './ChatLogRobot.vue'
export default {
    props: {
        currentUser: {
            type: Object,
            default: {}
        },
        connection: {
            type: Object,
        },
        messageList: {
            type: Array,
            default: []
        },
        canchat: {
            type: Boolean,
            default: true
        },
    },
    components: {
        ChatOrder,
        ChatLogRobot
    },
    data() {
        return {
            text: '',
            dataList: [],
            userInfo: {},
        };
    },

    methods: {
        sendMessage(type, message) {
            let e = window.event || arguments[0];
            if ((e.key == 'Enter' || e.code == 'Enter' || e.keyCode == 13) && !e.shiftKey || e.type == 'click') {
                e.returnValue = false;
                if (!message.trim()) {
                    this.$message.error('不能发送空消息');
                    return;
                }
                if (!this.canchat) {
                    this.$message.error('会话已断开，不能发送消息');
                    return;
                }
                this.connection.invoke("InvokeService", type, this.currentUser.source, String(this.userInfo.userId), String(this.currentUser.senderId), String(this.userInfo.name), String(this.currentUser.sender), message);
                return false
            }
        },
        async getOrderList() {
            this.$refs.chatOrder.openModal({
                Source: this.currentUser.source,
                SysUserId: this.currentUser.senderId
            });
        },
        async getUserRobotChatlog() {
            this.$refs.chatLogRobot.openModal({
                source: this.currentUser.source,
                chatUserId: this.currentUser.senderId
            });
        },
        uploadSuccess(response) {
            if (response.code == 0) {
                this.connection.invoke("InvokeService", 13, this.currentUser.source, String(this.userInfo.userId), String(this.currentUser.senderId), String(this.userInfo.name), String(this.currentUser.sender), response.data.url);
            } else {
                this.uploadError();
            }
        },
        uploadError() {
            this.$message.error('发送图片失败');
        },
    },
    created() {
        this.userInfo = this.$store.getters['chat/onlineUser'];
    },
    watch: {
        'messageList.length': {
            handler() {
                this.$nextTick(() => {
                    let scrollElem = this.$refs.scrollDiv;
                    if (scrollElem) scrollElem.scrollTo({ top: scrollElem.scrollHeight });
                });
            },
            deep: true,
            immediate: true
        },
        '$store.state.chat.onlineUser': {
            handler(v) {
                this.$nextTick(() => {
                    this.userInfo = v;
                });
            },
            deep: true,
            // immediate: true
        },
    },
};
</script>
<style lang="scss" scoped>
.chat-message {
    height: 100%;
    .chat-content {
        height: 70%;
        background: #ededed;
        overflow: auto;
        > .message {
            padding: 12px 18px;
        }
        > .left {
            text-align: left;
            .name {
                margin-right: 6px;
            }
            > div:nth-child(1) {
                margin-left: 40px;
                color: #c8c8c8;
                margin-bottom: 4px;
            }
            > div:nth-child(2) {
                display: flex;
                align-items: center;
                > span:nth-child(1) {
                    width: 32px;
                    height: 32px;
                    margin-right: 6px;
                    line-height: 32px;
                    vertical-align: middle;
                    flex-shrink: 0;
                }
                > div:nth-child(2):not(.image) {
                    background: #ffffff;
                    padding: 8px 14px;
                    border-radius: 30px;
                    max-width: 45%;
                    line-height: 1.5;
                    word-break: break-all;
                }
            }
        }
        > .right {
            text-align: right;
            .name {
                margin-left: 6px;
            }
            > div:nth-child(1) {
                margin-right: 40px;
                color: #c8c8c8;
                margin-bottom: 2px;
            }
            > div:nth-child(2) {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                > span:nth-child(2) {
                    width: 32px;
                    height: 32px;
                    line-height: 32px;
                    margin-left: 6px;
                    vertical-align: middle;
                }
                > div:nth-child(1):not(.image) {
                    background: #0096ff;
                    padding: 8px 14px;
                    border-radius: 30px;
                    max-width: 50%;
                    line-height: 1.5;
                    color: #fff;
                    text-align: left;
                    word-break: break-all;
                }
            }
        }
    }
    .opera-content {
        height: 42px;
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
        i {
            font-size: 22px;
            margin-right: 20px;
            cursor: pointer;
        }
        > div:nth-of-type(2) {
            display: flex;
            align-items: center;
            > div:nth-child(2),
            > div:nth-child(1) {
                background: #ff9541;
                border-radius: 16px;
                padding: 4px 18px;
                color: #fff;
                cursor: pointer;
            }
            > div:nth-child(1) {
                margin-right: 12px;
            }
        }
    }
    .typing-area {
        height: calc(30% - 44px);
        > div:nth-child(1) {
            height: calc(100% - 64px);
            outline: none;
            padding: 12px 12px 0 12px;
            overflow: auto;
            margin-bottom: 12px;
            font-size: 15px;
            width: calc(100% - 24px);
            :deep(.el-textarea__inner) {
                height: 100%;
                border: 0;
                padding: 0;
            }
        }
        > div:nth-child(2) {
            text-align: right;
            margin-right: 12px;
            :deep(.el-button) {
                width: 80px;
            }
        }
    }
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
