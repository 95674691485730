
import { post, doUpload } from '../index';
// 客服v2
// 获取source
export const getPrintSourceList = (data) => post('/api/v1/printSource/getPrintSourceList')(data)

// 客服配置
export const getCustomerConfig = (data) => post('/api/v2/chatCustomerConfig/getCustomerConfig')(data)
export const setCustomerConfig = (data) => post('/api/v2/chatCustomerConfig/setCustomerConfig')(data)
export const synCustomerConfig = (data) => post('/api/v2/chatCustomerConfig/synCustomerConfig')(data)

// 状态配置
export const getChatCustomerUserServiceList = (data) => post('/api/v2/chatCustomerUserService/getChatCustomerUserServiceList')(data)
export const updateChatCustomerUserService = (data) => post('/api/v2/chatCustomerUserService/updateChatCustomerUserService')(data)
export const addChatCustomerUserService = (data) => post('/api/v2/chatCustomerUserService/addChatCustomerUserService')(data)
export const delChatCustomerUserService = (data) => post('/api/v2/chatCustomerUserService/delChatCustomerUserService')(data)
export const updateChatCustomerUserServiceStatus = (data) => post('/api/v2/chatCustomerUserService/updateChatCustomerUserServiceStatus')(data)

// 问题分类
export const getChatQuestionTypeList = data => post('/api/v1/chatQuestion/getChatQuestionTypeList')(data)
export const editChatQuestionType = data => post('/api/v1/chatQuestion/editChatQuestionType')(data)
export const createChatQuestionType = data => post('/api/v1/chatQuestion/createChatQuestionType')(data)
export const delChatQuestionType = data => post('/api/v1/chatQuestion/delChatQuestionType')(data)
export const editChatQuestionTypeSort = data => post('/api/v1/chatQuestion/editChatQuestionTypeSort')(data)

// 问题
export const getChatQuestionList = data => post('/api/v1/chatQuestion/getChatQuestionList')(data)
export const delChatQuestion = data => post('/api/v1/chatQuestion/delChatQuestion')(data)
export const editChatQuestion = data => post('/api/v1/chatQuestion/editChatQuestion')(data)
export const createChatQuestion = data => post('/api/v1/chatQuestion/createChatQuestion')(data)
export const changeChatQuestionStatus = data => post('/api/v1/chatQuestion/changeChatQuestionStatus')(data)
export const changeChatQuestionTopStatus = data => post('/api/v1/chatQuestion/changeChatQuestionTopStatus')(data)
export const editChatQuestionSort = data => post('/api/v1/chatQuestion/editChatQuestionSort')(data)
export const synChatQuestion = data => post('/api/v1/chatQuestion/synChatQuestion')(data)
export const batchChangeChatQuestionStatus = data => post('/api/v1/chatQuestion/batchChangeChatQuestionStatus')(data)
export const batchChangeChatQuestionTopStatus = data => post('/api/v1/chatQuestion/batchChangeChatQuestionTopStatus')(data)


// 用户消息记录
export const getChatCustomerDataList = data => post('/api/v2/chatCustomerDataCount/getChatCustomerDataList')(data)
export const exportChatCustomerData = data => post('/api/v2/chatCustomerDataCount/exportChatCustomerData')(data)
export const getChatCustomerChatLogList = data => post('/api/v2/chatCustomerDataCount/getChatCustomerChatLogList')(data);
export const editChatCustomerDataQualityTesting = data => post('/api/v2/chatCustomerDataCount/editChatCustomerDataQualityTesting')(data);
export const getAllDailyCountChatCustomerData = data => post('/api/v2/chatCustomerDataCount/getAllDailyCountChatCustomerData')(data);
export const exportChatCustomerDataCount = data => post('/api/v2/chatCustomerDataCount/exportChatCustomerDataCount')(data);
export const getChatCustomerDataCountList = data => post('/api/v2/chatCustomerDataCount/getChatCustomerDataCountList')(data);
export const exportChatCustomerCommentDataCount = data => post('/api/v2/chatCustomerDataCount/exportChatCustomerCommentDataCount')(data);

// 人员管理
export const addChatCustomerUser = data => post('/api/v2/chatCustomerUser/addChatCustomerUser')(data)
export const getChatCustomerUserList = data => post('/api/v2/chatCustomerUser/getChatCustomerUserList')(data)
export const updateChatCustomerUser = data => post('/api/v2/chatCustomerUser/updateChatCustomerUser')(data)
export const getChatCustomerUserInfo = data => post('/api/v2/chatCustomerUser/getChatCustomerUserInfo')(data)
export const delChatCustomerUser = data => post('/api/v2/chatCustomerUser/delChatCustomerUser')(data)
export const getUserList = data => post('/api/v1/user/getUserList')(data)
export const changeChatCustomerUserService = data => post('/api/v2/chatCustomerUser/changeChatCustomerUserService')(data)


// 服务组管理
export const getChatUserGroupList = data => post('/api/v2/chatUserGroup/getChatUserGroupList')(data)
export const addChatUserGroup = data => post('/api/v2/chatUserGroup/addChatUserGroup')(data)
export const updateChatUserGroup = data => post('/api/v2/chatUserGroup/updateChatUserGroup')(data)
export const delChatUserGroup = data => post('/api/v2/chatUserGroup/delChatUserGroup')(data)

// 通知设置
export const getChatNoticeList = data => post('/api/v2/chatNotice/getChatNoticeList')(data)
export const delChatNotice = data => post('/api/v2/chatNotice/delChatNotice')(data)
export const addChatNotice = data => post('/api/v2/chatNotice/addChatNotice')(data)
export const updateChatNotice = data => post('/api/v2/chatNotice/updateChatNotice')(data)

// 机器人学习
export const getChatRobotQuestionList = data => post('/api/v2/chatRobotQuestion/getChatRobotQuestionList')(data)
// 新增加入智库
export const syncChatRobotQuestion = data => post('/api/v2/chatRobotQuestion/syncChatRobotQuestion')(data)
// 绑定先有库
export const syncChatRobotSmilarsQuestion = data => post('/api/v2/chatRobotQuestion/syncChatRobotSmilarsQuestion')(data)
// 删除机器人学习
export const delChatRobotQuestion = data => post('/api/v2/chatRobotQuestion/delChatRobotQuestion')(data)
// 客服留言板
export const getChatMessageBoardList = data => post('/api/v2/chatMessageBoard/getChatMessageBoardList')(data)
export const exportChatMessageBoard = data => post('/api/v2/chatMessageBoard/exportChatMessageBoard')(data)
// 获取移交客服列表
export const getChatCustomerTransferList = data => post('/api/v1/customerSwitchRecord/getChatCustomerUserList')(data)
export const exportChatCustomerTransfer = data => post('/api/v1/customerSwitchRecord/exportChatCustomerUser')(data)
// 修改客服留言板
export const updateChatMessageBoard = data => post('/api/v2/chatMessageBoard/updateChatMessageBoard')(data)
// 获取投诉列表
export const getChatCustomerComplaintRecordList = data => post('/api/v2/chatCustomerComplaintRecord/getChatCustomerComplaintRecordList')(data)
export const exportChatCustomerComplaintRecord = data => post('/api/v2/chatCustomerComplaintRecord/exportChatCustomerComplaintRecord')(data)
// 修改投诉
export const updateChatCustomerComplaintRecord = data => post('/api/v2/chatCustomerComplaintRecord/updateChatCustomerComplaintRecord')(data)
// 获取转接记录
export const getCustomerSwitchRecordList = data => post('/api/v1/customerSwitchRecord/getCustomerSwitchRecordList')(data)
export const exportCustomerSwitchRecord = data => post('/api/v1/customerSwitchRecord/exportCustomerSwitchRecord')(data)
// 获取通知记录列表
export const getChatNoticeLogList = data => post('/api/v2/chatNoticeLog/getChatNoticeLogList')(data)
export const exportChatNoticeLog = data => post('/api/v2/chatNoticeLog/exportChatNoticeLog')(data)
export const updateChatNoticeLog = data => post('/api/v2/chatNoticeLog/updateChatNoticeLog')(data)

// 会话转接
export const GetChatCustomerUserList= data => post('/api/v1/customerSwitchRecord/getChatCustomerUserList')(data)

// 聊天界面
export const getMySelfChatCustomerUserInfo = data => post('/api/v2/chatCustomerUser/getMySelfChatCustomerUserInfo')(data)
export const changeMySelfChatCustomerUserService = data => post('/api/v2/chatCustomerUser/changeMySelfChatCustomerUserService')(data)

// 常用回复
export const getChatCustomerCommonRepliesData = data => post('/api/v2/chatCustomerCommonReplies/getChatCustomerCommonRepliesData')(data)
export const addChatCustomerCommonReplies = data => post('/api/v2/chatCustomerCommonReplies/addChatCustomerCommonReplies')(data)
export const delChatCustomerCommonReplies = data => post('/api/v2/chatCustomerCommonReplies/delChatCustomerCommonReplies')(data)

// 获取转接客服
export const getChatCustomerUser = data => post('/api/v1/customerSwitchRecord/getChatCustomerUserList')(data)
// 获取当前服务类型
export const getChatCustomerSessionInfo = data => post('/api/v2/chatCustomerSession/getChatCustomerSessionInfo')(data)
// 转接
export const customerTransfer = data => post('/api/v1/customerSwitchRecord/transfer')(data)
// 订单
export const getOrderPageList = data => post('/api/v1/order/getOrderList')(data)
export const transfer = data => post('/api/v2/chatNoticeLog/transfer')(data)
