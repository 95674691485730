<template>
    <el-dialog :visible.sync="visible" :width="width" :title="title" append-to-body :before-close="beforeClose" @closed="onClosed" :fullscreen="fullscreen">
        <slot></slot>
        <span slot="footer" class="dialog-footer" v-if="(showCancel || showConfirm )&& showFooter">
            <el-button @click="emitEvent(false)" v-if="showCancel">{{ cancelText }}</el-button>
            <el-button :loading="isConfirmLoading || isLoading" type="primary" @click="confirm()" v-if="showConfirm">{{ confirmText }}</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: '提示'
            },
            width: {
                type: Number | String,
                default: '40%'
            },
            showCancel: {
                type: Boolean,
                default: true
            },
            showConfirm: {
                type: Boolean,
                default: true
            },
            showFooter: {
                type: Boolean,
                default: true
            },
            cancelText: {
                type: String,
                default: '取 消'
            },

            confirmText: {
                type: String,
                default: '确 定'
            },
            fullscreen: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                isConfirmLoading: false,
                isLoading: false
            }
        },

        methods: {
            emitEvent(e) {
                this.$emit('cancel')
                this.$emit('update:visible', e)
            },

            confirm() {
                this.$emit('confirm')
            },

            beforeClose() {
                this.emitEvent(false)
                return
            },

            onClosed() {
                this.$nextTick(() => {
                    this.$emit('closed')
                })
            }
        }
    }
</script>

<style scoped>

</style>
