<!--
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-03-18 13:31:33
 * @LastEditors: llg
 * @LastEditTime: 2022-10-10 10:25:48
-->
<template>
    <div class="submenu">
        <div v-for="item in list" :key="item.id">
            <el-submenu v-if="isNotEmpty(item.children) && isDisplay(item) && isChildrenAllDisplay(item.children)" :index="item.path || item.title">
                <template slot="title">
                    <i :class="item.icon" v-if="item.icon"></i>
                    <i class="el-icon-tickets" v-else></i>
                    <span style="margin-left:8px; margin-right: 10px;">{{item.title}}</span>

                    <!-- 系统管理 -->
                    <el-badge v-if="item.id === 129 && $store.getters['menu/notReadCount'] > 0"
                              :value="$store.getters['menu/notReadCount']" class="item"
                              style="margin-top: -4px;"
                    >
                    </el-badge>
                </template>
                <submenu :list="item.children" :isMobile="isMobile" @close="closeMenu" />
            </el-submenu>

            <el-menu-item v-else-if="!isNotEmpty(item.children) && isDisplay(item)" :index="item.path || item.title" @click="goPage(item)">
                <i :class="item.icon" v-if="item.icon"></i>
                <i class="el-icon-tickets" v-else></i>
                <template slot="title">
                    <span style="margin-left:8px; margin-right: 10px;">{{item.title}}</span>


                    <!-- 公告管理 -->
                    <el-badge v-if="item.id === 1082 && $store.getters['menu/getNotReadNoticeCount'] > 0"
                              :value="$store.getters['menu/getNotReadNoticeCount']" class="item"
                              style="margin-top: -4px;"
                    >
                    </el-badge>
                    <!--<div class="not-read">-->
                    <!--    {{ $store.state.menu.notReadNoticeCount }}-->
                    <!--</div>-->

                    <!-- 订单消息 -->
                    <el-badge v-if="item.id === 1083 && $store.getters['menu/getNotReadOrderCount'] > 0"
                              :value="$store.getters['menu/getNotReadOrderCount']" class="item"
                              style="margin-top: -4px;"
                    >
                    </el-badge>
                </template>
            </el-menu-item>
        </div>
    </div>
</template>

<script>

export default {
    name: "Submenu",
    props: {
        list: Array,
        isMobile: Boolean,
    },
    methods: {
        goPage(item) {
            let { path, params } = item;
            let { path: currentPath } = this.$route;
            let isNewSite = path.indexOf("http") !== -1 ? true : false;
            let { innerWidth } = window;
            if (innerWidth < 800) {
                this.$store.commit("menu/setMenuOpen", false);
            }

            // 打开新窗口
            if (isNewSite) {
                window.open(item.path, "_blank");
                return;
            }

            this.closeMenu();
            if (currentPath !== path) {
                this.$router.push({ path, params });
            }
        },
        closeMenu() {
            if (this.isMobile) {
                this.$emit("close");
            }
        },
        isNotEmpty(children) {
            return children && children.length > 0;
        },
        isDisplay(item) {
            return item.display && !item.meta.isDetail;
        },

        isChildrenAllDisplay(children) {
            if (children && children.length) {
                return children.some(val => {
                    return val.display;
                })
            }
        },

        aaa(children) {
            if (children && children.length) {
                return children.every(val => {
                    return val.display;
                })
            }
        }

    },
};
</script>

<style lang="scss" scoped>
    :deep(.el-menu-item) {
        //display: flex;
        //align-items: center;
    }
</style>
