<!--
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-02-21 12:34:20
 * @LastEditors: llg
 * @LastEditTime: 2022-10-11 14:23:23
-->
<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import { getMenuList } from "@/api/menuApi.js";
import { buildRoute } from "@/utils/buildRoute";
import store from '@/store';

export default {
    components: {
    },
    name: "App",
    data() {
        return {
        }
    },
    async created() {
        this.initState();
        const menuList = await getMenuList();
        console.log(menuList,'menuList');
        // if (menuList.length > 1)  store.dispatch('setting/setCurrentApp', menuList[0]);
        store.dispatch('menu/setAppList', menuList);
        buildRoute(menuList);
    },
    methods: {
        // 初始化State
        initState() {
            this.$store.dispatch("user/initState");
            this.$store.dispatch("worktab/initState");
            this.$store.dispatch("setting/initState");
            this.$store.dispatch("listView/initState");
            this.$store.dispatch("search/initState");
            this.$store.dispatch("order/initState");
        },
    },
};
</script>

<style lang="scss">
</style>
