/*
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-05-27 16:56:11
 * @LastEditors: llg
 * @LastEditTime: 2022-06-09 14:12:13
 */
import axios from '@/utils/request';

// 商家端
export function getBCustOrderDailyLogPageList(params) {
    return axios.post({
        url: '/api/v1/bCustOrderDailyLog/getBCustOrderDailyLogPageList',
        data: params
    }).then((res) => {
        return res
    })
}

export function getBCustOrderDailyLogSum(params) {
    return axios.post({
        url: '/api/v1/bCustOrderDailyLog/getBCustOrderDailyLogSum',
        data: params
    }).then((res) => {
        return res
    })
}

export function exportBCustOrderDailyLogPageList(params) {
    return axios.post({
        url: '/api/v1/bCustOrderDailyLog/exportBCustOrderDailyLogPageList',
        data: params
    }).then((res) => {
        return res
    })
}

// admin端
export function getCustOrderDailyLogPageList(params) {
    return axios.post({
        url: '/api/v1/custOrderDailyLog/getCustOrderDailyLogPageList',
        data: params
    }).then((res) => {
        return res
    })
}

export function getCustOrderDailyLogSum(params) {
    return axios.post({
        url: '/api/v1/custOrderDailyLog/getCustOrderDailyLogSum',
        data: params
    })
}

export function exportCustOrderDailyLogPageList(params) {
    return axios.post({
        url: '/api/v1/custOrderDailyLog/exportCustOrderDailyLogPageList',
        data: params
    }).then((res) => {
        return res
    })
}