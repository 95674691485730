/*
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-09-28 10:21:19
 * @LastEditors: llg
 * @LastEditTime: 2022-10-12 13:38:36
 */
import { doPost, doGet, post } from '../index'

// 应用管理
export const getSysSource = data => doPost('/api/v1/sysSource/getSysSource')(data)
export const getSysSourceInfo = data => doPost('/api/v1/sysSource/getSysSourceInfo')(data)
export const upSysSource = data => doPost('/api/v1/sysSource/upSysSource')(data)
export const delSysSource = data => doPost('/api/v1/sysSource/delSysSource')(data)
export const addSysSource = data => doPost('/api/v1/sysSource/addSysSource')(data)

// 导航管理
export const getMenu = data => post('/api/v1/menu/getMenu')(data)
export const updateMenu = data => post('/api/v1/menu/updateMenu')(data)
export const addMenu = data => post('/api/v1/menu/addMenu')(data)
export const delMenu = data => post('/api/v1/menu/delMenu')(data)

// 角色管理
export const createRole = data => doPost('/api/v1/roles/createRole')(data)
export const getRoleList = data => doPost('/api/v1/roles/getRoleList')(data)
export const upRoleIsOpen = data => doPost('/api/v1/roles/upRoleIsOpen')(data)
export const updateRole = data => doPost('/api/v1/roles/updateRole')(data)
export const getIsOpenRole = data => doPost('/api/v1/roles/getIsOpenRole')(data)

// 用户管理
export const getUserList = data => doPost('/api/v1/user/getUserList')(data)
export const addUser = data => doPost('/api/v1/user/addUser')(data)
export const editUser = data => doPost('/api/v1/user/editUser')(data)
export const delUser = data => doPost('/api/v1/user/delUser')(data)
export const editUserPassword = data => doPost('/api/v1/user/editUserPassword')(data)
export const updUserSuspend = data => post('/api/v1/user/updUserSuspend')(data)

// 获取当前用户导航
export const getUserPower = data => doPost('/api/v1/user/getUserPower')(data)

// 省市运费配置
export const getSysAreaShippingFeeList = data => doPost('/api/v1/sysAreaShippingFee/getSysAreaShippingFeeList')(data)
export const addSysAreaShippingFee = data => doPost('/api/v1/sysAreaShippingFee/addSysAreaShippingFee')(data)
export const editSysAreaShippingFee = data => doPost('/api/v1/sysAreaShippingFee/editSysAreaShippingFee')(data)
export const deleteSysAreaShippingFee = data => doPost('/api/v1/sysAreaShippingFee/deleteSysAreaShippingFee')(data)


// 配送模式
export const getDeliverModelList = data => doPost('/api/v1/deliverModel/getDeliverModelList')(data)
export const editDeliverModel = data => doPost('/api/v1/deliverModel/editDeliverModel')(data)
export const addDeliverModel = data => doPost('/api/v1/deliverModel/addDeliverModel')(data)
export const deleteDeliverModel = data => doPost('/api/v1/deliverModel/deleteDeliverModel')(data)

// 获取项目配送时效列表
export const getSourceDeliverModeList = data => doPost('/api/v1/sourceDeliverMode/getSourceDeliverModeList')(data)
// 获取设置打印模式列表
export const getPrintModeAndSetList = data => doPost('/api/v1/sourceDeliverMode/getPrintModeAndSetList')(data)
// 设置打印模式
export const setPrintMode = data => doPost('/api/v1/sourceDeliverMode/setPrintMode')(data)
// 获取设置售价模式
export const getCalculatePriceMode = data => doPost('/api/v1/sourceDeliverMode/getCalculatePriceMode')(data)
// 设置售价模式
export const setCalculatePriceMode = data => doPost('/api/v1/sourceDeliverMode/setCalculatePriceMode')(data)
// 设置状态
export const setStatus = data => doPost('/api/v1/sourceDeliverMode/setStatus')(data)
// 城市信息
export const getSysAreaList = data => doPost('/api/v1/sysArea/getSysAreaList')(data)
// 获取顺丰快递配送产品
export const getSfExpressTypeList = data => doPost('/api/v1/sourceDeliverMode/getSfExpressTypeList')(data)
// 编辑项目配送时效
export const editSourceDeliverMode = data => doPost('/api/v1/sourceDeliverMode/editSourceDeliverMode')(data)
// 获取项目配送时效数据
export const getSourceDeliverModeInfo = data => doPost('/api/v1/sourceDeliverMode/getSourceDeliverModeInfo')(data)
// 获取操作日志
export const getSourceDeliverModeOperateLogList = data => doPost('/api/v1/sourceDeliverMode/getSourceDeliverModeOperateLogList')(data)

// 获取顺丰配置列表
export const getSfConfigList = data => doPost('/api/v1/sfConfig/getSfConfigList')(data)
// 获取单个顺丰配置信息
export const getSfConfigInfo = data => doPost('/api/v1/sfConfig/getSfConfigInfo')(data)
// 添加顺丰配置
export const addSfConfig = data => doPost('/api/v1/sfConfig/addSfConfig')(data)
// 更改顺丰配置
export const updateSfConfig = data => doPost('/api/v1/sfConfig/updateSfConfig')(data)
// 获取顺丰开通产品列表
export const getSfActivateTypeList = data => doPost('/api/v1/sfConfig/getSfExpressTypeList')(data)
// 更改顺丰开通产品
export const updateSfExpressType = data => doPost('/api/v1/sfConfig/updateSfExpressType')(data)
// 删除顺丰配置
export const delSfConfig = data => doPost('/api/v1/sfConfig/delSfConfig')(data)

export const getMenuList = data => post('/api/v1/menu/getMenuList')(data)
