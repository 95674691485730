/**
 * table滚动
 */

const state = {
    scrollData: []
}

const getters = {
    getScrollData: (state) => {
        state.scrollData
    }
}


const mutations = {
    setScrollData(state, data) {
        console.log(state, data)
        if (!state.scrollData.length) {
            state.scrollData.push(data)
        } else {
            for (let i = 0; i < state.scrollData.length; i++) {
                if (Object.keys(data)[0] == Object.keys(state.scrollData[i])[0]) {
                    state.scrollData[i] = data;
                } else {
                    state.scrollData.push(data)
                }
            }
        }
    },
}

const actions = {
    setScrollData({ commit }, e) {
        commit('setScrollData', e)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}