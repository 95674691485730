import axios from '@/utils/request'
import { doPost } from './index'

const dictMap = new Map()
export const getDict = async selectKey => {
    if (dictMap.get(selectKey)) return dictMap.get(selectKey)

    try {
        const { data } = await doPost('/adminapi/v1/common/getSelectOptionData')({
            selectKey
        })
        dictMap.set(selectKey, data)
        return dictMap.get(selectKey)
    } catch {
        throw new Error()
    }

}

export function pageInfo() {
    return axios.get({
        url: '/api/Dictionary/PageInfo',
    }).then((res) => {
        return res
    })
}

export function formatInfo() {
    return axios.get({
        url: '/api/Dictionary/FormatInfo',
    }).then((res) => {
        return res
    })
}

export function paperType() {
    return axios.get({
        url: '/api/Dictionary/PaperType',
    }).then((res) => {
        return res
    })
}

export function printType() {
    return axios.get({
        url: '/api/Dictionary/PrintType',
    }).then((res) => {
        return res
    })
}

export function logisticCompany() {
    return axios.get({
        url: '/api/Dictionary/LogisticCompany',
    }).then((res) => {
        return res
    })
}


export function getOrderDownloadStatus(type) {
    return axios.get({
        url: '/api/Dictionary/GetOrderDownloadStatus/' + type,
    }).then((res) => {
        return res
    })
}

export function getOrderStatus() {
    return axios.get({
        url: '/api/Dictionary/GetOrderStatus',
    }).then((res) => {
        return res
    })
}

export function getRoleList() {
    return axios.get({
        url: '/api/SysRole/GetRoleList',
    }).then((res) => {
        return res
    })
}

export function getUserSysType() {
    return axios.get({
        url: '/api/Dictionary/GetUserSysType',
    }).then((res) => {
        return res
    })
}

export function businessState() {
    return axios.get({
        url: '/api/Dictionary/BusinessState',
    }).then((res) => {
        return res
    })
}

export function businessGrade() {
    return axios.get({
        url: '/api/Dictionary/BusinessGrade',
    }).then((res) => {
        return res
    })
}

export function businessActive() {
    return axios.get({
        url: '/api/Dictionary/BusinessActive',
    }).then((res) => {
        return res
    })
}

export function businessType() {
    return axios.get({
        url: '/api/Dictionary/BusinessType',
    }).then((res) => {
        return res
    })
}

export function businessRecommend() {
    return axios.get({
        url: '/api/Dictionary/BusinessRecommend',
    }).then((res) => {
        return res
    })
}

export function businessTopEnum() {
    return axios.get({
        url: '/api/Dictionary/BusinessTopEnum',
    }).then((res) => {
        return res
    })
}

export function getAppointStatus() {
    return axios.get({
        url: '/api/Dictionary/GetAppointStatus',
    }).then((res) => {
        return res
    })
}

export function getPickMethod() {
    return axios.get({
        url: '/api/Dictionary/GetPickMethod',
    }).then((res) => {
        return res
    })
}

export function getDeliverMethod() {
    return axios.get({
        url: '/api/Dictionary/GetDeliverMethod',
    }).then((res) => {
        return res
    })
}

export function getWpsSettleState() {
    return axios.get({
        url: '/api/Dictionary/GetWpsSettleState',
    }).then((res) => {
        return res
    })
}

export function getWpsIsTicket() {
    return axios.get({
        url: '/api/Dictionary/GetWpsIsTicket',
    }).then((res) => {
        return res
    })
}

export function auditState() {
    return axios.get({
        url: '/api/Dictionary/AuditState',
    }).then((res) => {
        return res
    })
}

export function getIFlytekStatus() {
    return axios.get({
        url: '/api/Dictionary/GetIFlytekStatus',
    }).then((res) => {
        return res
    })
}

export function refundType() {
    return axios.get({
        url: '/api/Dictionary/RefundType',
    }).then((res) => {
        return res
    })
}

// 报备
export function getAppointmentSource() {
    return axios.get({
        url: '/api/v1/common/getAppointmentSource',
    }).then((res) => {
        return res
    })
}

export function getAppointmentStatus() {
    return axios.get({
        url: '/api/v1/common/getAppointmentStatus',
    }).then((res) => {
        return res
    })
}


export function withdrawType() {
    return axios.get({
        url: '/api/Dictionary/WithdrawType',
    }).then((res) => {
        return res
    })
}


export function paymentStatus() {
    return axios.get({
        url: '/api/Dictionary/PaymentStatus',
    }).then((res) => {
        return res
    })
}
