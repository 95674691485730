<!--
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-09-09 10:45:34
 * @LastEditors: llg
 * @LastEditTime: 2022-10-20 11:10:17
-->
<template>
    <div class="message-notice">
        <template v-for="(item, index) in data.messageNoticeData">
            <notifications v-show="!minimize" :group="item.group" :duration="-1" :closeOnClick="false" position="bottom right" :width="400" :speed="500" animation-type="velocity" :key="index">
                <template slot="body">
                    <div class="message-notice-wrap">
                        <div class="badge" :class="{'badgeMore':isMoreType}">
                            <el-badge :value="data.messageNoticeData.length" :max="99" v-show="item.showBage = data.messageNoticeData.length > 2? true : false"></el-badge>
                        </div>
                        <div class="close" v-if="isMoreType">
                            <img src="./images/close.png" alt="" @click="$notify({ group: item.group, clean: true })">
                        </div>
                        <div class="header">
                            <div class="start">
                                <img src="./images/notice.png" alt="">
                            </div>
                            <div class="end">
                                <div>
                                    <span class="blue">DING</span>
                                    <span class="time">{{ item.release_time }}</span>
                                </div>
                                <div class="desc">接收到通知类型：平台公告</div>
                            </div>
                        </div>
                        <template>
                            <div class="content" v-if="!isMoreType">
                                <div class="title">{{ item.notice_title }}</div>
                                <div class="text">
                                    <p v-html="item.notice_content"></p>
                                </div>
                            </div>
                            <div class="content" v-else>
                                <div class="title">{{ item.release_time }}</div>
                            </div>
                        </template>

                        <template>
                            <div class="btn" v-if="!isMoreType">
                                <el-button type="primary" @click="userReads(item)">已知悉</el-button>
                            </div>
                            <div class="btn" v-else>
                                <el-button type="primary" @click="userReads(item)">查看更多</el-button>
                            </div>
                        </template>

                    </div>
                </template>
            </notifications>
        </template>
        <template v-for="item in data.orderNoticeData">
            <notifications v-show="!minimize" :group="item.group" :duration="-1" :closeOnClick="false" position="bottom right" :width="400" :speed="500" animation-type="velocity">
                <template slot="body">
                    <div class="message-notice-wrap" style="height: 160px;">
                        <div class="badge" :class="{'badgeMore':isMoreType}">
                            <el-badge :value="data.orderNoticeData.length" :max="99" v-show="item.showBage = data.orderNoticeData.length > 2? true : false"></el-badge>
                        </div>
                        <div class="close" v-if="isMoreType">
                            <img src="./images/close.png" alt="" @click="$notify({ group: item.group, clean: true })">
                        </div>
                        <div class="header">
                            <div class="start">
                                <img src="./images/order.png" alt="">
                            </div>
                            <div class="end">
                                <div>
                                    <span class="blue">DING</span>
                                    <span class="time">接收到通知类型：订单消息提醒</span>
                                </div>
                                <div class="desc">您有新的订单请及时处理</div>
                            </div>
                        </div>
                        <template>
                            <div class="content" style="font-size: 14px;" v-if="!isMoreType">
                                订单来源：{{ item.source }}，订单编号：{{ item.cust_order_id }}，订单时间：{{ item.order_time }}
                                <!--<div class="title">{{ item.notice_title }}</div>-->
                            </div>
                        </template>

                        <div class="btn">
                            <el-button @click="$notify({ group: item.group, clean: true })" v-if="!isMoreType">关闭</el-button>
                            <el-button type="primary" @click="$notify({ group: item.group, clean: true }); $router.push({ name: 'business.setting.order-notice' })">
                                查看更多
                            </el-button>
                        </div>
                    </div>
                </template>
            </notifications>
        </template>

        <template v-for="item in data.taskNoticeData">
            <notifications v-show="!minimize" :group="item.group" :duration="-1" :closeOnClick="false" position="bottom right" :width="400" :speed="500" animation-type="velocity">
                <template slot="body">
                    <div class="message-notice-wrap" style="height: 160px;">
                        <div class="badge" :class="{'badgeMore':isMoreType}">
                            <el-badge :value="data.taskNoticeData.length" :max="99" v-show="item.showBage = data.taskNoticeData.length > 2? true : false"></el-badge>
                        </div>
                        <div class="close" v-if="isMoreType">
                            <img src="./images/close.png" alt="" @click="$notify({ group: item.group, clean: true })">
                        </div>
                        <div class="header">
                            <div class="start">
                                <img src="./images/task.png" alt="">
                            </div>
                            <div class="end">
                                <div>
                                    <span class="blue">DING</span>
                                    <span class="time">接收到通知类型：任务中心</span>
                                </div>
                                <div class="desc">您提交的下载任务已完成</div>
                            </div>
                        </div>
                        <div class="content" style="font-size: 14px;" v-if="!isMoreType">
                            导出类型：{{ item.export_type_label }}，导出时间：{{ item.export_time }},导出的文件系统已生成完毕可前往下载！
                            <!--<div class="title">{{ item.notice_title }}</div>-->
                        </div>

                        <div class="btn">
                            <el-button @click="$notify({ group: item.group, clean: true })" v-if="!isMoreType">关闭</el-button>
                            <el-button type="primary" @click="$notify({ group: item.group, clean: true }); $router.push({ name: $route.matched[0].name +'.task-center.task-my' })">
                                查看更多
                            </el-button>
                        </div>
                    </div>
                </template>
            </notifications>
        </template>

        <template v-for="item in data.shipmentsNoticeData">
            <notifications v-show="!minimize" :group="item.group" :duration="-1" :closeOnClick="false" position="bottom right" :width="400" :speed="500" animation-type="velocity">
                <template slot="body">
                    <div class="message-notice-wrap" style="height: 160px;">
                        <div class="badge" :class="{'badgeMore':isMoreType}">
                            <el-badge :value="data.shipmentsNoticeData.length" :max="99" v-show="item.showBage = data.shipmentsNoticeData.length > 2? true : false"></el-badge>
                        </div>
                        <div class="close" v-if="isMoreType">
                            <img src="./images/close.png" alt="" @click="$notify({ group: item.group, clean: true })">
                        </div>
                        <div class="header">
                            <div class="start">
                                <img src="./images/order.png" alt="">
                            </div>
                            <div class="end">
                                <div>
                                    <span class="blue">DING</span>
                                    <span class="time">{{ item.order_time }}</span>
                                </div>
                                <div class="desc">接收到通知类型：订单配送</div>
                            </div>
                        </div>
                        <div class="content" style="font-size: 14px;" v-if="!isMoreType">
                            <div>{{ item.cust_order_id }}，{{item.message_type_status === 0 ? '物流商接单失败' : '物流商接单成功'}}</div>
                        </div>

                        <div class="btn">
                            <el-button @click="$notify({ group: item.group, clean: true })" v-if="!isMoreType">忽略</el-button>
                            <el-button type="primary" @click="readShipmentsNotice(item)">
                                查看
                            </el-button>
                        </div>
                    </div>
                </template>
            </notifications>
        </template>

    </div>
</template>

<script>
import { noticeBusinessApi } from '@/api/noticeApi'
import { enqueueNoticeAudioQueue } from '@/utils/noticeAudio';

const niticeConfig = {
    announcement: {
        type: 'announcement',
        interval: 0,
        priority: 0,
        audio: require('@/assets/audio/announcement.mp3'),
    },
    order: {
        type: 'order',
        interval: 10000,
        priority: 1,
        audio: require('@/assets/audio/order.mp3'),
    },
    order: {
        type: 'task',
        interval: 0,
        priority: 0,
        audio: require('@/assets/audio/order.mp3'),
    },
    shipments: {
        type: 'shipments',
        interval: 0,
        priority: 0,
        audio: require('@/assets/audio/order.mp3'),
    }
}

export default {
    name: 'message-notice',
    props: {
        data: {
            type: Object,
            default: () => ({}),
        }
    },
    data() {
        return {
            minimize: false,
            canClick: true,
            showBage: false
        };
    },

    computed: {
        isMoreType() {
            let list = this.filterDataKey()
            return list.length >= 2 ? true : false
        }
    },

    watch: {
        'data.messageNoticeData'(val, oldVal) {
            if (oldVal.at(-2)) {
                this.$notify({ group: oldVal.at(-2).group, clean: true });
            }

            this.$nextTick(() => {
                this.show(val.at(-1))
                console.log(val, 'val');
            })
        },

        'data.orderNoticeData'(val, oldVal) {
            if (oldVal.at(-2)) {
                this.$notify({ group: oldVal.at(-2).group, clean: true });
            }

            this.$nextTick(() => {
                val.showBage = val.length > 2 ? true : false
                this.show(val.at(-1))
            })
        },


        'data.taskNoticeData'(val, oldVal) {
            if (oldVal.at(-2)) {
                this.$notify({ group: oldVal.at(-2).group, clean: true });
            }
            this.$nextTick(() => {
                this.show(val.at(-1))
                console.log(val, 'val');

            })
        },

        'data.shipmentsNoticeData'(val, oldVal) {
            if (oldVal.at(-2)) {
                this.$notify({ group: oldVal.at(-2).group, clean: true });
            }
            this.$nextTick(() => {
                this.show(val.at(-1))
                console.log(val, 'val');
            })
        }
    },

    mounted() {
        // this.show({ type: 'shipments' })

        // this.data.shipmentsNoticeData.push({
        //     group: 'order-shipments-notice',
        //     type: 'shipments',
        //     interval: 0,
        // })

        //   this.data.shipmentsNoticeData.push({
        //     group: 'order-shipments-notice',
        //     type: 'shipments',
        //     interval: 0,
        // })

        // this.data.taskNoticeData.push({
        //     group: 'task-notice',
        //     type: 'task',
        //     interval: 0,
        // })
    },

    methods: {
        show(opt = {}) {
            this.$notify({ group: opt.group });
            this.canClick = true;
            enqueueNoticeAudioQueue({ ...niticeConfig[opt.type] })
        },
        filterDataKey() {
            let list = []
            for (const key in this.data) {
                if (this.data[key].length) {
                    list.push(key)
                }
            }
            return list
        },
        closeNotification() {
            if (this.$refs.myNotification.active && this.$refs.myNotification.active.length) {
                this.$notify.close(this.$refs.myNotification.active[0].id)
            }
        },

        async userReads(item) {
            this.$notify({ group: item.group, clean: true });
            const [err, res] = await noticeBusinessApi.userReads({
                notification_ids: [item.notification_id]
            })
            if (err) return
            this.$message.success(res.msg)
        },

        async readShipmentsNotice(item) {
            this.$notify({ group: item.group, clean: true });
            const [err, res] = await noticeBusinessApi.orderShipmentsReads({
                ids: [item.order_id]
            })
            if (err) return;
            this.$message.success(res.msg);
            const sys_type = this.$store.state.user.user.info.sys_type;;
            this.$router.push({
                name: sys_type == 'SysAdmin' ?  'admin.order.order' : 'business.order.order',
                query: {
                    cust_order_id: item.cust_order_id
                }
            })
        }
    },
};
</script>

<style lang="scss" scoped>
.minimize {
    //position: fixed;
    //right: 0px;
    //top: 80vh;
    border: 1px solid #ddd;
    padding: 6px 12px;
    border-radius: 16px 0px 0px 16px;
    background: rgb(25, 26, 35);
    color: #fff;
    cursor: pointer;
    z-index: 99999;
    user-select: none;
}

.vue-notification-group {
    z-index: 4000 !important;
    position: relative !important;
}

.message-notice-wrap {
    position: relative;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 10px;
    height: 140px;
    background: #fff !important;
    color: #000 !important;
    border-left: 0 !important;
    .badge {
        position: absolute;
        top: 0;
        right: 0;
        &.badgeMore {
            position: absolute;
            top: 15px;
            left: 10px;
        }
    }
    .close {
        position: absolute;
        top: 2px;
        right: 2px;
        img {
            width: 20px;
            height: 20px;
        }
    }
    .header {
        display: flex;

        .start {
            img {
                width: 40px;
                height: 40px;
            }
        }

        .end {
            margin-left: 10px;

            .blue {
                color: #1a85fa;
                font-weight: bold;
            }

            .time {
                margin-left: 10px;
            }

            .desc {
                margin-top: 4px;
                font-size: 14px;
                color: #b5b5b5;
            }
        }
        .end_btn {
            position: absolute;
            bottom: 5px;
            right: 5px;
        }
    }

    .content {
        margin-top: 12px;
        padding-left: 50px;

        .title {
            font-weight: bold;
            font-size: 18px;
        }

        .text {
            word-break: break-all;
        }
    }

    .btn {
        display: flex;
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
}

.vue-notification-wrapper {
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 30%);
    font-size: 15px;
    border-radius: 8px 8px 0 0;
}
</style>
