const state = {
    searchParamsCache: {},
}

const getters = {
    searchParamsCache: (state) => state.searchParamsCache,
}

const mutations = {
    initState() {
        let searchParamsCache = JSON.parse(localStorage.getItem("sys_search_params_cache"))
        if (searchParamsCache) {
            state.searchParamsCache = searchParamsCache;
        }
    },
    setSearchParamsCache(state, searchParamsCache) {
        let searchParams = JSON.parse(localStorage.getItem("sys_search_params_cache")) || {};
        if (!searchParams[searchParamsCache.routeName]) {
            searchParams[searchParamsCache.routeName] = searchParamsCache.params
        } else {
            searchParams[searchParamsCache.routeName] = searchParamsCache.params;
        }
        state.searchParamsCache = searchParams;
        saveStoreStorage(searchParams);
    },
    clearSearchParamsCache() {
        localStorage.removeItem('sys_search_params_cache');
        state.searchParamsCache = {};
    }

}

const actions = {
    initState({ commit }, e) {
        commit('initState', e)
    },
    setSearchParamsCache({ commit }, e) {
        commit('setSearchParamsCache', e)
    },
    clearSearchParamsCache({ commit }, e) {
        commit('clearSearchParamsCache', e)
    }
}

function saveStoreStorage(params) {
    localStorage.setItem("sys_search_params_cache", JSON.stringify(params))
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}