<template>
    <div>
        <div class="chat-header">
            <div class="left">
                <span>{{currentUser.sender}}</span>
                <span @click="closeMessage" v-if="currentUser.senderId && canchat">结束对话</span>
            </div>
            <div class="right">
                <span @click="transfer">
                    <i class="el-icon-refresh-left"></i>
                    <span>转接</span>
                </span>
                <span @click="getChatTransferList">
                    <i class="el-icon-alarm-clock"></i>
                </span>
            </div>
        </div>
        <chat-transfer ref="chatTransfer" :currentUser="currentUser" :sourceList="sourceList"/>
        <chat-transfer-list ref="chatTransferList" />
    </div>
</template>

<script>
import ChatTransfer from './ChatTransfer.vue'
import ChatTransferList from './ChatTransferList.vue'
export default {
    props: {
        currentUser: {
            type: Object,
            default: () => { return {} }
        },
        sourceList: {
            type: Array,
            default: () => { return [] }
        },
        canchat: {
            type: Boolean,
            default: true
        },
    },
    components: {
        ChatTransfer,
        ChatTransferList
    },
    data() {
        return {
            options: [],
            statusId: ''
        };
    },

    methods: {
        // 转接 
        async transfer() {
            this.$refs.chatTransfer.visible = true;
            this.$refs.chatTransfer.isTransfer = false;
            this.$refs.chatTransfer.getCustomer(this.currentUser.source);
        },
        getChatTransferList() {
            this.$refs.chatTransferList.openModal()
        },
        closeMessage() {
            this.$confirm('确定结束对话吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$emit('closeConnection');
                this.$message({
                    type: 'success',
                    message: '结束!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消'
                });
            });
        }
    },
    created() {
    }
};
</script>
<style lang="scss" scoped>
.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    user-select: none;
    .left {
        > span:nth-child(1) {
            margin-right: 12px;
            font-weight: bold;
            font-size: 14px;
        }
        > span:nth-child(2) {
            border: 1px solid #00d2ff;
            border-radius: 15px;
            padding: 4px 16px;
            cursor: pointer;
            color: #00d2ff;
        }
    }
    .right {
        i {
            font-size: 18px;
            cursor: pointer;
            vertical-align: middle;
        }
        > span:nth-child(1) {
            cursor: pointer;
            span {
                vertical-align: middle;
                color: #00d2ff;
            }
            i {
                color: #00d2ff;
                margin-right: 4px;
            }
        }
        > span:nth-child(2) {
            margin-left: 30px;
        }
        // > span:nth-child(2) {
        //     border: 1px solid #00d2ff;
        //     border-radius: 15px;
        //     padding: 4px 16px;
        //     cursor: pointer;
        // }
    }
}
</style>
