/*
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-09-26 11:18:03
 * @LastEditors: llg
 * @LastEditTime: 2022-10-09 18:11:24
 */
/**
 * 菜单|权限列表
 */

const state = {
  menuList: {},
  menuWidth: '',
  menuOpen: true,
  appList: [],

  // 未读通知数量
  notReadNoticeCount: 0,
  // 未读订单数量
  notReadOrderCount: 0
}

const getters = {
  menu: (state) => state.menuList,
  app: (state) => state.appList,

  getNotReadNoticeCount: state => {
    if (state.notReadNoticeCount > 99) {
      return '99+'
    }
    return state.notReadNoticeCount
  },

  getNotReadOrderCount: state => {
    if (state.notReadOrderCount > 99) {
      return '99+'
    }
    return state.notReadOrderCount
  },

  notReadCount: state => {
    if (state.notReadNoticeCount + state.notReadOrderCount > 99) {
      return '99+'
    }
    return state.notReadNoticeCount + state.notReadOrderCount
  }
}

const mutations = {
  setMenuList(state, list) {
    state.menuList = list
  },
  setAppList(state, list) {
    state.appList = list
  },
  setMenuWidth(state, e) {
    state.menuWidth = e
  },
  setMenuOpen(state, e) {
    state.menuOpen = e
  },

  setNotReadNoticeCount(state, num) {
    state.notReadNoticeCount = num
  },

  setNotReadOrderCount(state, num) {
    state.notReadOrderCount = num
  }
}

const actions = {
  setMenuList({ commit }, e) {
    commit('setMenuList', e)
  },
  setAppList({ commit }, e) {
    commit('setAppList', e)
  },
  setMenuWidth({ commit }, e) {
    commit('setMenuWidth', e)
  },
  setMenuOpen({ commit }, e) {
    commit('setMenuOpen', e)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
