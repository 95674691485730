<!--
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-10-20 14:37:36
 * @LastEditors: llg
 * @LastEditTime: 2022-10-25 14:48:10
-->
<template>
    <div class="order">
        <el-dialog width="550px" :visible.sync="visible" title="订单" :modal-append-to-body='false' append-to-body>
            <el-table :data="data" border style="width: 100%" v-loading="loading" :height="'460px'">
                <el-table-column prop="custOrderId" label="订单号" width="300">
                </el-table-column>
                <el-table-column prop="orderTime" label="订单日期"></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import { getOrderList } from '@/api/chatApi';
export default {
    data() {
        return {
            visible: false,
            loading: false,
            data: []
        };
    },
    methods: {
        openModal(params) {
            this.visible = true;
            this.getOrderList(params);
        },
        async getOrderList(params) {
            this.loading = true;
            const res = await getOrderList('get', params)
            if (res.code == 0) {
                this.data = res.data;
                this.loading = false;
            }
        }
    },
    created() {
    },
};
</script>

<style lang="scss" scoped>
</style>