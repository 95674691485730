<template>
    <div>
        <notifications v-show="!minimize" ref="messageNotification" :group="'messageNotification'" :duration="-1"
            :closeOnClick="false" position="bottom right" :width="400" :speed="500" animation-type="velocity"
            id="messageNotice">
            <template slot="body">
                <div class="my-notification">
                    <div class="content">
                        <div class="message">
                            <div class="left">
                                <div class="img">
                                </div>
                            </div>
                            <div class="right">
                                <div>
                                    <span>DING</span><span>2023-07-19 18:45:12</span>
                                </div>
                                <p>接收到通知类型：客服超时未回复通知，来自服务组：{XXX}，用户：{XXX}向客服：{XXX}咨询问题后的紧急通知，请及时处理</p>
                            </div>
                        </div>
                        <div class="btn">
                            <el-button @click="minimize = true">稍后处理</el-button>
                            <el-button type="primary">立即处理</el-button>
                        </div>

                    </div>
                </div>
            </template>

        </notifications>
        <audio :src="audioSrc" id="audio" @ended="overAudio"></audio>
        <div v-show="minimize" class="minimize" @click="messageClick" @mousedown="mousedown" :style="{ top: messageTop }">
            通知 </div>
    </div>
</template>

<script>
import { getChatCustomerTransferList } from '@/api/phpApi/chatApiV2'
export default {
    name: 'MessageNotification',
    components: {},
    props: {
        data: {
            type: Array,
            default: [],
            audio: null,
        }
    },
    data() {
        return {
            visible: false,
            minimize: false,
            canClick: true,
            messageTop: '80vh',
            audioSrc: require('@/assets/audio/message.mp3'),
            play: false,
            timer: null
        };
    },

    methods: {
        show(group = 'messageNotification') {
            this.$notify({ group });
            this.canClick = true;
            this.visible = true;
        },
        mousedown(e) {
            setTimeout(() => {
                this.canClick = true;
                //去除默认样式 - 避免拖动元素出现禁止图标
                e.preventDefault && e.preventDefault();
                //获取目标元素
                let odiv = e.target;

                //算出鼠标相对元素的位置
                let disY = e.clientY - odiv.offsetTop;
                let clientHeight = document.documentElement.clientHeight || document.body.clientHeight

                //监听鼠标移动事件
                document.onmousemove = (e) => {
                    //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                    let top = e.clientY - disY;

                    //重新赋值

                    if (top >= clientHeight - 30) {
                        top = clientHeight - 30;
                    } else if (top <= 110) {
                        top = 110
                    }
                    this.messageTop = top + 'px';
                    this.canClick = false;
                };

                //监听鼠标松开
                document.onmouseup = (e) => {
                    document.onmousemove = null;
                    document.onmouseup = null;
                    setTimeout(() => {
                        this.canClick = true;
                    })

                };
            }, 20)
        },
        messagePlay() {
            if (this.audio != null) {
                this.audio.pause();
                this.audio = null;
            }
            this.audio = document.getElementById('audio');
            const promise = this.audio.play();
            if (promise !== undefined) {
                promise.then(res => {

                }).catch(error => {
                    this.$confirm('是否授权本次访问自动播放音频?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        if (this.audio) this.audio.play();
                    }).catch(() => {

                    });
                })
            }
        },
        overAudio() {
            if (this.audio && !this.timer && this.data.length) {
                this.timer = setTimeout(() => {
                    this.audio.play();
                    clearTimeout(this.timer);
                    this.timer = null;
                }, 60000)
            }
        },
        stopAudio() {
            if (this.audio) {
                this.audio.pause();
                this.audio = null;
            }
        },
        messageClick() {
            if (!this.canClick) return;
            this.minimize = false;
        },
        async getChatCustomerTransferList() {
            const [err,res] = await getChatCustomerTransferList({
                page: 1,
                limit: 99
            })
            if (err) return
            console.log(res);
        }
    },
    created() {
        // this.getChatCustomerTransferList()
    }
}
</script>

<style lang="scss" scoped>
.minimize {
    position: fixed;
    right: 0px;
    top: 80vh;
    border: 1px solid #ddd;
    padding: 6px 12px;
    border-radius: 16px 0px 0px 16px;
    background: rgb(25, 26, 35);
    color: #fff;
    cursor: pointer;
    z-index: 99999;
    user-select: none;
}

.vue-notification-group {
    z-index: 4000 !important;
}

.vue-notification-wrapper {
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 30%);
    font-size: 15px;
    border-radius: 8px 8px 0 0;

    .my-notification {
        background: #fff !important;
        color: #000 !important;
        border-left: 0 !important;

        .content {
            padding: 6px 0 0px 14px;
            font-size: 13px;
            position: relative;

            .message {
                display: flex;
                overflow: auto !important;
                height: 174px;


                .left {
                    width: 40px;
                    height: 40px;
                    padding-left: 20px;
                    margin-top: 10px;

                    .img {
                        width: 100%;
                        height: 100%;
                        background: url(../../assets//img/icon1.png) no-repeat;
                        background-size: cover;
                    }
                }

                .right {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 270px;
                    margin-bottom: 40px;
                    margin-left: 10px;

                    >div {
                        height: 10px;
                        padding-top: 5px;

                        >span:first-of-type {
                            width: 200px;
                            color: blue;
                            font-size: 15px;
                            font-weight: 700;

                        }

                        >span:last-of-type {
                            padding-left: 10px;
                            font-size: 15px;
                            font-weight: 700;

                        }

                    }

                    >p {
                        font-size: 14px;
                        letter-spacing: 2px;
                        font-weight: 400;

                    }
                }
            }

            .btn {
                position: absolute;
                bottom: 5px;
                right: 20px;

            }
        }
    }


}
</style>
