<!--
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-10-20 14:37:36
 * @LastEditors: llg
 * @LastEditTime: 2022-10-25 14:48:10
-->
<template>
    <div class="order">
        <el-dialog width="800px" :visible.sync="visible" title="转接记录" :modal-append-to-body='false' append-to-body>
            <sy-table :listView="listView" @loadData="getTransferpage">
            </sy-table>
        </el-dialog>
    </div>
</template>

<script>
import { transferpage } from '@/api/chatApi';
import { listView } from './listView';
export default {
    data() {
        return {
            listView: listView,
            visible: false,
            loading: false,
            data: []
        };
    },
    methods: {
        openModal() {
            this.visible = true;
            this.$nextTick(() => {
                this.getTransferpage();
            })
        },
        async getTransferpage() {
            this.listView.loading = true;
            const res = await transferpage('post', {
                pageNum: this.listView.listQuery.page || 1,
                pageSize: this.listView.listQuery.limit || 10
            })
            if (res.code == 0) {
                this.listView.data = res.data.result;
                this.listView.total = res.data.totalNum;
                this.listView.loading = false;
            }
        },
        parseTime(date) {
            return new Date(date).toLocaleString()
        }
    },
    created() {
    },
};
</script>

<style lang="scss" scoped>
</style>