/*
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-09-22 16:30:45
 * @LastEditors: llg
 * @LastEditTime: 2022-10-18 10:31:20
 */
import { getUserPower } from '@/api/phpApi/sysApi';
import router from '@/router';
import store from '@/store';
import { errHandler } from '@/utils/utils'
import { Loading, Message } from 'element-ui';

export async function getMenuList() {
	return new Promise(async (resolve, reject) => {
		let sys = JSON.parse(localStorage.getItem("sys"))
		let isLogin = false

		if (sys) {
			isLogin = sys.user.isLogin
		}
		if (!isLogin) {
			return
		}

		const loading = Loading.service({
			customClass: 'sys-loading',
			lock: true,
			text: '系统配置加载中...',
			spinner: 'el-icon-loading',
		});
		const [err, res] = await errHandler(getUserPower);
		// 获取权限导航
		if (err) {
			// loading.spinner = 'el-icon-warning-outline';
			// loading.text = '系统配置加载失败';
			loading.close();
			return;
		}
		if (res.code == 401) {
			store.dispatch("user/setLoginStatus", false);
			store.dispatch("user/setUserInfo", { user_info: {} });
			router.push('/login');
			Message.error(res.message);
			resolve();
		} else {
			resolve(res.data);

		}
		loading.close();
	})
	// 插入权限导航

	// loading.close();
}

// function buildNavMenu(menu) {
// 	for (let i = 0; i < menu.length; i++) {
// 		if (menu[i].sonMenu) menu[i].children = menu[i].sonMenu;
// 		if (menu[i].children && menu[i].children.length) {
// 			menu[i].children = buildNavMenu(menu[i].children)
// 		}
// 	}
// 	return menu;
// }
